import { featureCollection, points } from '@turf/helpers';
import difference from '@turf/difference';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import clone from '@turf/clone';

const erodeWithGeometry = (baseGeometry, erosionGeometry) => {
  let remaining = clone(baseGeometry);
  // need to go through all the erosion features and subtract them from the polygon
  // most of the time this should only be a few features
  // why are feature collections nested like this?
  for (let i = 0; i < erosionGeometry.features?.features?.length; i += 1) {
    const feature = erosionGeometry.features.features[i];
    remaining = difference(featureCollection([remaining, feature]));
    // shorctut if the geometry is fully gone
    // but this shouldn't happen, should we throw an exception?
    if (remaining === null) {
      return null;
    }
  }
  return remaining;
};

const invertMask = (maskGeometry) => {
  const globalCoverage = bboxPolygon(bbox(points([[-180, 90], [180, -90]])));
  return erodeWithGeometry(globalCoverage, maskGeometry);
};

export const checkMaskCoverage = (viewport, maskGeometry) => {
  // if the viewport spans 180 longitude we need to split it into 2 and run this whole thing twice
  const viewportBbox = bbox(points(viewport));
  if (viewportBbox[0] < -180) {
    const geom1 = bboxPolygon([360 + viewportBbox[0], viewportBbox[1], 180, viewportBbox[3]]);
    const geom2 = bboxPolygon([-180, viewportBbox[1], viewportBbox[2], viewportBbox[3]]);
    return erodeWithGeometry(geom1, maskGeometry) === null
      && erodeWithGeometry(geom2, maskGeometry) === null;
  }
  if (viewportBbox[2] > 180) {
    const geom1 = bboxPolygon([viewportBbox[0], viewportBbox[1], 180, viewportBbox[3]]);
    const geom2 = bboxPolygon([-180, viewportBbox[1], -360 + viewportBbox[2], viewportBbox[3]]);
    return erodeWithGeometry(geom1, maskGeometry) === null
      && erodeWithGeometry(geom2, maskGeometry) === null;
  }
  const remainingBbox = bboxPolygon(bbox(points(viewport)));
  return erodeWithGeometry(remainingBbox, maskGeometry) === null;
};

/**
 * @param {*} maskGeometry
 * @returns a bounding box for the area covered by this overlay
 */
export const coverageBbox = (maskGeometry) => {
  // make a polygon covering the whole world
  const remainingBbox = bboxPolygon(bbox(points([[-180, 90], [180, -90]])));
  const coverage = erodeWithGeometry(remainingBbox, maskGeometry);
  // this shouldn't happen unless the mask geometry is messed up,
  // so this is just protecting against something strange happening
  if (coverage === null) {
    return bbox(points([[-180, 90], [180, -90]]));
  }
  return bbox(coverage);
};

export const runCoverageCheck = (overlay) => {
  if (overlay.activeMask) {
    const mask = overlay.activeMask;
    const viewportBbox = overlay.map.getBounds().toArray();
    const show = checkMaskCoverage(viewportBbox, mask.geometry);
    if (show) {
      overlay.setMaskMessage(mask.message);
      overlay.setShowMaskMessage(true);
      overlay.setOverlayCoverageBbox(mask.coverage);
    }
    else {
      overlay.setShowMaskMessage(false);
    }
  }
};

const getColor = (step, currentBaseMap) => {
  if (step.color_dark && currentBaseMap?.is_dark) {
    return step.color_dark;
  }

  return step.color_light;
};

const getLabelLayerBelow = (overlay) => {
  const labelBelowLayers = [
    'contour-label',
    'location-labels',
    'settlement-labels',
    'settlement-minor-label',
  ];

  return labelBelowLayers.find((labelBelowLayer) => overlay.map.getLayer(labelBelowLayer)) || '';
};

/**
* @see https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
*/
export const invertColor = (color, limit = 140) => {
  const hex = color.slice(1);

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // 140 seems like a better val for intensity check
  return ((r * 0.299) + (g * 0.587) + (b * 0.114)) > limit ? '#000' : '#fff';
};

export const colorInterpolate = (steps, currentBaseMap) => {
  const colors = [];

  steps.forEach((step) => {
    colors.push(step.value, getColor(step, currentBaseMap));
  });

  return colors;
};

export const addFill = (params = {}, overlay, currentBaseMap) => {
  const {
    fillOpacity = overlay.overlayOpacity,
    tile,
    visibility = 'visible',
  } = params;

  const layerBelow = overlay.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';
  const { name } = tile.index_json;

  overlay.map.addLayer({
    'id': name,
    'type': 'fill',
    'source': name,
    'source-layer': tile.source_layer_id,
    'filter': overlay.filter || true,
    'layout': {
      visibility,
      'fill-sort-key': ['get', 'DN'],
    },
    'paint': {
      'fill-opacity': fillOpacity,
      'fill-opacity-transition': {
        duration: overlay.opacityTransition || 0,
      },
      'fill-antialias': true,
      'fill-outline-color': 'rgba(0,0,0,0)',
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'DN'],
        ...colorInterpolate(overlay.mapSource.legends[0].steps, currentBaseMap),
      ],
    },
  }, layerBelow);

  return { id: name, opacities: ['fill-opacity'] };
};

export const addLabels = (params = {}, overlay) => {
  if (!overlay.textFieldLabel) {
    return undefined;
  }

  const {
    filter = ['>=', ['get', 'DN'], 1],
    layerBelow,
    layout = {
      visibility: 'none',
    },
    minzoom = 7,
    paint = {
      'text-opacity': 0,
    },
    tile,
  } = params;

  const labelLayerBelow = layerBelow || getLabelLayerBelow(overlay);
  const { name } = tile.index_json;
  const labelLayerId = `${name}-label`;

  overlay.map.addLayer({
    'id': labelLayerId,
    'type': 'symbol',
    'source': name,
    'source-layer': tile.source_layer_id,
    minzoom,
    filter,
    'layout': {
      'symbol-placement': 'line',
      'symbol-sort-key': ['*', ['get', 'DN'], -1],
      'symbol-spacing': 150,
      'text-field': overlay.textFieldLabel || '',
      'text-font': overlay.mapStyle.textFont,
      'text-letter-spacing': overlay.mapStyle.textLetterSpacing,
      'text-line-height': overlay.mapStyle.textLineHeight,
      'text-padding': overlay.mapStyle.textPadding,
      'text-pitch-alignment': 'viewport',
      'text-rotation-alignment': 'viewport',
      'text-size': overlay.mapStyle.textSize,
      ...layout,
    },
    'paint': {
      'text-color': overlay.mapStyle.textColor,
      'text-halo-color': overlay.mapStyle.textHaloColor,
      'text-halo-blur': overlay.mapStyle.textHaloBlur,
      'text-halo-width': overlay.mapStyle.textHaloWidth,
      ...paint,
    },
  }, labelLayerBelow);

  return {
    id: labelLayerId,
    moveOnShow: labelLayerBelow,
    opacities: ['text-opacity'],
  };
};

export const addSource = (params = {}, overlay) => {
  const {
    tile,
    type = 'vector',
    ...rest // Allow caller to specify additional arbitrary parameters
  } = params;

  const mapSourceId = tile.index_json.name;

  try {
    overlay.map.addSource(mapSourceId, {
      ...tile.index_json,
      type,
      ...rest,
    });
  }
  catch (e) {
    return null;
  }

  return mapSourceId;
};

export const addMask = (params = {}, overlay) => {
  const {
    tile,
    visibility = 'visible',
    geomOnly = false,
  } = params;

  if ((!tile && !overlay.mapSource?.mask) || (tile && !tile?.mask)) {
    return null;
  }

  let name;
  let maskGeometry;
  let maskLayerGeometry;
  let maskMessage;
  let coverageGeometry;
  // if this is a tileset specific mask, we need to get the geometry from the overlay mask
  // and combine it and the tileset mask
  if (tile?.mask) {
    name = tile.index_json.name;
    maskLayerGeometry = tile.mask.mask_geojson ?? tile.mask.geojson;
    maskGeometry = featureCollection(tile.mask.mask_geojson ?? tile.mask.geojson);
    coverageGeometry = invertMask(maskGeometry);
    maskMessage = tile.mask.message ?? overlay.mapSource.mask.message ?? 'Overlay not available in this area';
  }
  else {
    name = overlay.source.short_name;
    maskLayerGeometry = overlay.mapSource.mask.mask_geojson ?? overlay.mapSource.mask.geojson;
    maskGeometry = featureCollection(overlay.mapSource.mask.mask_geojson ?? overlay.mapSource.mask.geojson);
    coverageGeometry = invertMask(maskGeometry);
    maskMessage = overlay.mapSource.mask.message;
  }
  if (geomOnly) {
    return {
      sources: [],
      layers: [],
      geometry: maskGeometry,
      coverage: coverageBbox(maskGeometry),
      message: maskMessage,
    };
  }
  const maskSourceId = `${name}-mask-source`;
  const coverageSourceId = `${name}-coverage-source`;
  const fillLayerId = `${name}-mask`;
  const outlineLayerId = `${name}-mask-outline`;
  const labelLayerId = `${name}-mask-label`;

  if (!overlay.map.getSource(maskSourceId)) {
    overlay.map.addSource(maskSourceId, {
      type: 'geojson',
      data: maskLayerGeometry,
    });
  }

  if (!overlay.map.getSource(coverageSourceId)) {
    overlay.map.addSource(coverageSourceId, {
      type: 'geojson',
      data: coverageGeometry,
    });
  }

  const layerBelow = overlay.map.getLayer('hillshade') ? 'hillshade' : 'contour-line';
  overlay.map.addLayer({
    id: fillLayerId,
    type: 'fill',
    source: maskSourceId,
    layout: {
      visibility,
    },
    paint: {
      'fill-opacity': 0,
      'fill-opacity-transition': {
        duration: 0,
      },
      'fill-color': overlay.mapStyle.maskColor,
      'fill-antialias': false,
    },
  }, layerBelow);

  overlay.map.addLayer({
    id: outlineLayerId,
    type: 'line',
    source: coverageSourceId,
    layout: {
      visibility,
    },
    paint: {
      'line-opacity': 0,
      'line-opacity-transition': {
        duration: 0,
      },
      'line-color': '#000000',
      'line-width': 2,
    },
  }, layerBelow);

  overlay.map.addLayer({
    id: labelLayerId,
    type: 'symbol',
    source: coverageSourceId,
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 200,
      'text-allow-overlap': true,
      'text-field': 'Overlay Boundary',
      'text-font': overlay.mapStyle.textFont,
      'text-padding': 100,
      'text-max-angle': 45,
      'text-size': overlay.mapStyle.textSize,
      visibility,
    },
    paint: {
      'text-color': overlay.mapStyle.textColor,
      'text-halo-color': overlay.mapStyle.textHaloColor,
      'text-halo-blur': overlay.mapStyle.textHaloBlur,
      'text-halo-width': overlay.mapStyle.textHaloWidth,
      'text-opacity': 0,
      'text-opacity-transition': {
        duration: 0,
      },
    },
  }, getLabelLayerBelow(overlay));

  return {
    sources: [maskSourceId, coverageSourceId],
    layers: [
      {
        id: fillLayerId, opacities: ['fill-opacity'], opacityValues: [overlay.mapStyle.maskOpacity], toggleOpacityOnly: true,
      },
      {
        id: outlineLayerId, opacities: ['line-opacity'], opacityValues: [0.5], toggleOpacityOnly: true,
      },
      {
        id: labelLayerId, opacities: ['text-opacity'], opacityValues: [0.7], toggleOpacityOnly: true,
      },
    ],
    geometry: maskGeometry,
    coverage: coverageBbox(maskGeometry),
    message: maskMessage,
  };
};

/**
 * Given an array of images, return true if every external image has been loaded in the map.
 * @param {Object[]} images The images to be displayed in the map
 * @param {string} images[].id  A unique name for the image
 * @param {string} images[].src {string} URL for the image
 * @param {Object} map Mapbox map instance
 * @returns True if every image has been added to the map, false if not.
 */
export const hasImages = (images, map) => images.every(({ id }) => map.hasImage(id));

/**
 * Given an array of images, load each external image in the map. Each external image is rendered
 * in an HTML ImageElement, and then added to the map when loaded, so the images can be used as an
 * icon-image for a symbol layer.
 * @param {Object[]} images The images to be displayed in the map
 * @param {string} images[].id  A unique name for the image
 * @param {string} images[].src {string} URL for the image
 * @param {string} images[].width {number} width of the image
 * @param {string} images[].height {number} height of the image
 * @param {Object} map Mapbox map instance
 * @returns An single promise that will be resolved when all external images have been loaded.
 * @see https://gist.github.com/ryanhamley/3d6844349ae27cae3a087b028228f8cf
 */
export const addImages = (images, map) => {
  const promises = images.map(({ id, src, width, height }) => new Promise((resolve) => {
    const img = new Image(width, height);
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      map.addImage(id, img, { pixelRatio: 2 });
      resolve();
    };
    img.src = src;
  }));

  return Promise.all(promises);
};
