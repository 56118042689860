<template>
  <div
    v-if="showTooltip"
    :class="containerClass"
  >
    <div
      :class="contentClass"
      @click="handleClick"
    >
      <img
        :class="imageClass"
        :src="imageUrl"
      >
      <div class="tw-text-white tw-font-normal">
        <p class="tw-mb-2.5">
          {{ messages[0] }}
        </p>
        <p class="tw-text-sm">
          {{ messages[1] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { localStorageService } from '@@/utils/StorageService';
import { useMapStore } from '@@/stores/Map';

export default {
  name: 'Tooltip',

  data() {
    return {
      hasSeenMapTooltip: false,
    };
  },

  computed: {
    ...mapState(useMapStore, {
      isLayerDrawerOpen: (state) => state.ui.isLayerDrawerOpen,
      mapCard: (state) => state.ui.mapCard,
      sources: (state) => state.data.sources,
    }),

    ...mapState(useMapStore, ['currentOverlay']),

    containerClass() {
      return [
        'tw-flex',
        'tw-flex-col',
        'tw-justify-center',
        'tw-items-center',
        'tw-w-full',
        this.$style.container,
      ];
    },

    contentClass() {
      return [
        'tw-flex',
        'tw-w-11/12',
        'lg:tw-w-1/2',
        'tw-p-5',
        'tw-rounded-lg',
        'tw-text-center',
        'lg:tw-text-left',
        'tw-cursor-pointer',
        this.$style.content,
      ];
    },

    imageClass() {
      return [
        'tw-hidden',
        'lg:tw-block',
        'tw-w-40',
        'tw-h-24',
        'tw-mr-4',
        'tw-rounded-lg',
        'tw-border',
        'border-color',
      ];
    },

    imageUrl() {
      return this.sources?.rala?.image_url;
    },

    messages() {
      return [
        'Use our mapping tools to find the best snow!',
        'Add weather overlays like snow depth and radar, and change base map styles to see topography and terrain features.',
      ];
    },

    showTooltip() {
      return this.currentOverlay === undefined
        && !this.mapCard
        && !this.hasSeenMapTooltip
        && !this.isLayerDrawerOpen;
    },
  },

  watch: {
    /**
     * Update local storage when the data value changes. This ensures that when the data value
     * changes the corresponding value in local storage is updated.
     * @see https://v2.vuejs.org/v2/cookbook/client-side-storage.html?redirect=true
     */
    hasSeenMapTooltip(value) {
      if (value) {
        localStorageService.setItem('hasSeenMapTooltip', value);
      }
    },

    /**
     * Don't show tooltip after layer drawer has been opened.
     */
    isLayerDrawerOpen(value) {
      if (value) {
        this.hasSeenMapTooltip = true;
      }
    },
  },

  /**
   * Read value from local storage and save to data when component is mounted. This mounts the
   * component with its data value initially set to the corresponding value from local storage.
   * @see https://v2.vuejs.org/v2/cookbook/client-side-storage.html?redirect=true
   */
  mounted() {
    this.hasSeenMapTooltip = localStorageService.getItem('hasSeenMapTooltip');
  },

  methods: {
    ...mapActions(useMapStore, ['setIsLayerDrawerOpen', 'setMapUiProperties']),

    handleClick() {
      this.hasSeenMapTooltip = true;
      this.setMapUiProperties({ selectedLayerDrawerTab: 0 });
      this.setIsLayerDrawerOpen(true);
    },
  },
};
</script>

<style module>
.container::after {
  border-color: var(--saturated-navy) transparent transparent;
  border-width: 1rem 1rem 0;
  content: '';
  display: block;
  height: 0;
  width: 0;
}

.content {
  background-color: var(--saturated-navy);
}
</style>
