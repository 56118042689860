import { mapActions, mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';
import {
  addFill, addLabels, addMask, addSource, runCoverageCheck,
} from '../Utils';

/**
 * The AnimatedOverlay mixin is used in Overlays which can be animated to show the progression of
 * weather features over time. The Overlay will have a series of frames, each representing the
 * weather feature at a particular time, and these frames can be animated by setting the opacity
 * of the previous frame's layers to 0 and then setting the opacity of the current frame's layers
 * to > 0. The <AnimationControl> will set the currentFrame value in the Map Vuex module in a
 * timeout when animating the overlay. When the currentFrame value changes then the watch in this
 * mixin will show the current frame's layers and hide the previous frame's layers.
 */
export default {
  data() {
    return {
      staleCheckIntervalId: null,
      overlayMask: null,
      activeMask: null,
    };
  },

  computed: {
    ...mapState(useMapStore, {
      currentFrame: (state) => state.ui.currentFrame,
      isLayerDrawerOpen: (state) => state.ui.isLayerDrawerOpen,
      showAnimationControl: (state) => state.ui.showAnimationControl,
      showOpacity: (state) => state.ui.showOpacity,
      center: (state) => state.ui.center,
    }),
  },

  watch: {
    currentFrame(currentFrame, previousFrame) {
      if (this.isActive) {
        this.hideFrame(previousFrame);
        this.showFrame(currentFrame);
      }
    },

    isLayerDrawerOpen() {
      if (this.isActive) {
        this.keepAnimationControlVisible();
      }
    },

    showOpacity() {
      if (this.isActive) {
        this.keepAnimationControlVisible();
      }
    },

    center() {
      if (this.isActive) {
        runCoverageCheck(this);
      }
    },
  },

  destroyed() {
    this.clearStaleCheckInterval();
  },

  methods: {
    ...mapActions(useMapStore, [
      'fetchMapSourcesTiles',
      'setMapUiProperties',
      'setShowAnimationControl',
      'setShowMaskMessage',
      'setMaskMessage',
      'setOverlayCoverageBbox',
    ]),

    async add() {
      await this.updateMapSourceAndFrames();
      this.startStaleCheckInterval();
      this.addHandleIdle();

      if (this.frames.length > 0) {
        this.show();
        return;
      }

      // When adding the source and layers for each tile to the map, note that the fill layers are
      // added with visibility set to visible with fill-opacity set to 0, and the label layers are
      // added with visibility hidden. This is done because when animating the overlay, and
      // showing/hiding each frame, change the visibility of the fill layers from none to visible
      // was seen to cause them to flash when visibility was initially set to visible. Adding the
      // fill layers to the map as visible, and then changing the opacity to show/hide them, is
      // seen to resolve this issue and leads to smoother animation between frames.

      this.addOverlayMask();

      this.tiles.forEach((tile) => {
        try {
          this.addFrame(tile);
        }
        catch (e) {
          // Ignore tiles if they can't be added to the map
        }
      });

      this.show();
    },

    addAnimatedOverlayEvents() {
      this.map.on('movestart', this.handleMovestart);
    },

    addOverlayMask(params = {}) {
      const {
        geomOnly = false,
      } = params;
      const overlayMask = addMask({ geomOnly }, this);
      if (overlayMask) {
        this.overlayMask = overlayMask;
      }
    },

    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;
      const source = addSource({ tile }, this);

      if (!source) {
        return;
      }

      const layer = addFill({ fillOpacity: 0, tile }, this, this.currentBaseMap);
      const labels = addLabels({ tile }, this);
      const sources = [source];
      const layers = [layer];
      const tilesetMask = addMask({ tile }, this);

      this.frames.push({
        labels,
        layers,
        sources,
        source_timestamp,
        mask: tilesetMask,
      });
    },

    addHandleIdle(isMovestart = false) {
      const handleIdle = () => {
        this.map.off('idle', handleIdle);
        this.setMapUiProperties({ isMapIdle: true });

        if (!isMovestart) {
          this.addAnimatedOverlayEvents();
        }
      };

      this.setMapUiProperties({ isMapIdle: false });
      this.map.on('idle', handleIdle);
    },

    clearStaleCheckInterval() {
      window.clearInterval(this.staleCheckIntervalId);
      this.staleCheckIntervalId = null;
    },

    handleMovestart() {
      this.addHandleIdle(true);
    },

    showMask(mask, forceOpacityValue = null) {
      this.activeMask = mask;
      mask.layers.forEach((l) => this.showLayer(l, forceOpacityValue));
    },

    hideMask(mask) {
      if (mask.layers) {
        mask.layers.forEach(this.hideLayer);
      }
    },

    /**
     * When hiding an animated overlay, i.e. when it is no longer the current overlay, each frame's
     * fill and label layer are set to visibility none, and the fill layer is set to have opacity 0.
     * And the animation control is hidden.
     */
    hide() {
      this.clearStaleCheckInterval();
      this.removeAnimatedOverlayEvents();

      this.frames.forEach((frame) => {
        const {
          labels, layer, layers, mask,
        } = frame;

        if (labels) {
          [labels].flat().forEach(this.hideLayer);
        }

        if (layers) {
          layers.forEach(this.hideLayer);
        }
        else {
          this.hideLayer(layer);
        }
        if (mask) {
          this.hideMask(mask);
        }
      });

      if (this.overlayMask) {
        this.hideMask(this.overlayMask);
      }

      if (typeof this.handleHide === 'function') {
        this.handleHide();
      }

      this.setShowAnimationControl(false);
    },

    hideFrame(index) {
      if (this.frames[index]) {
        const hideLayer = (layer) => {
          layer.opacities.forEach((opacity) => this.map.setPaintProperty(layer.id, opacity, 0));
        };

        const {
          labels, layer, layers, mask, removeEvents,
        } = this.frames[index];

        if (typeof removeEvents === 'function') {
          removeEvents();
        }

        if (labels) {
          [labels].flat().forEach(hideLayer);
        }

        if (layers) {
          layers.forEach((l) => hideLayer(l));
        }
        else {
          hideLayer(layer);
        }

        if (mask) {
          this.hideMask(mask);
        }
      }
    },

    /**
     * If the layer drawer is not open and the opacity control is not visible then show the
     * animation control.
     */
    keepAnimationControlVisible() {
      if (this.isLayerDrawerOpen === false && this.showOpacity === false) {
        this.setShowAnimationControl(true);
      }
    },

    remove() {
      this.clearStaleCheckInterval();
      this.removeAnimatedOverlayEvents();

      if (typeof this.handleRemove === 'function') {
        this.handleRemove();
      }

      if (typeof this.removeEvents === 'function') {
        this.removeEvents();
      }

      while (this.frames.length) {
        this.removeFrame(0);
      }

      if (this.overlayMask) {
        this.removeMask(this.overlayMask);
        this.overlayMask = null;
      }
    },

    removeAnimatedOverlayEvents() {
      this.map.off('movestart', this.handleMovestart);
    },

    removeMask(mask) {
      if (mask.layers) {
        mask.layers.forEach(this.removeLayer);
      }
      if (mask.sources) {
        mask.sources.forEach(this.removeSource);
      }
    },

    removeLayer(l) {
      if (this.map.getLayer(l.id)) {
        this.map.removeLayer(l.id);
      }
    },

    removeSource(s) {
      if (this.map.getSource(s)) {
        this.map.removeSource(s);
      }
    },

    removeFrame(index) {
      const {
        labels,
        layer,
        layers,
        source,
        sources,
        removeEvents,
        mask,
      } = this.frames[index];

      if (labels) {
        [labels].flat().forEach(this.removeLayer);
      }

      if (typeof removeEvents === 'function') {
        removeEvents();
      }

      if (layers) {
        layers.forEach(this.removeLayer);
      }
      else {
        this.removeLayer(layer);
      }

      if (source) {
        this.removeSource(source);
      }
      else if (sources) {
        sources.forEach((s) => this.removeSource(s));
      }

      if (mask) {
        this.removeMask(mask);
      }

      this.frames.splice(index, 1);
    },

    hideLayer(l) {
      l.opacities.forEach((opacity) => this.map.setPaintProperty(l.id, opacity, 0));
      if (!l.toggleOpacityOnly) {
        this.map.setLayoutProperty(l.id, 'visibility', 'none');
      }
    },

    showLayer(l, forceOpacityValue = null) {
      l.opacities.forEach((opacity, idx) => {
        // allow layers to set opacities independently for each opacity property
        // and allow more complex expressions or functions to be used in addition
        // to numeric values between 0 and 1
        const opacityValue = (forceOpacityValue ?? l.opacityValues?.[idx]) ?? null;

        if (opacityValue === null) {
          return this.map.setPaintProperty(l.id, opacity, this.overlayOpacity);
        }

        return this.map.setPaintProperty(l.id, opacity, opacityValue instanceof Function
          ? opacityValue(this.overlayOpacity)
          : opacityValue);
      });
      if (!l.toggleOpacityOnly) {
        this.map.setLayoutProperty(l.id, 'visibility', 'visible');
      }
    },

    /**
     * When showing an animated overlay, i.e. when it has already been added to the map and has
     * been selected as the current overlay again, then each frame's fill layer has visibility set
     * to visible and opacity set to 0. Then the current frame is shown along with the animation
     * control.
     */
    show() {
      this.frames.forEach((frame) => {
        const {
          labels, layer, layers, mask,
        } = frame;

        if (layers) {
          layers.forEach((l) => this.showLayer(l, 0));
        }
        else {
          this.showLayer(layer, 0);
        }

        if (labels) {
          [labels].flat().forEach((l) => this.showLayer(l, 0));
        }
        if (mask) {
          this.showMask(mask, 0);
        }
      });

      let currentFrame;

      if (typeof this.findCurrentFrame === 'function') {
        currentFrame = this.findCurrentFrame();
      }
      else {
        currentFrame = this.isForecast ? 0 : this.frames.length - 1;
      }

      this.setMapUiProperties({ currentFrame });

      this.setMapUiProperties({ numberOfFrames: this.frames.length });
      this.setShowAnimationControl(true);

      this.setShowMaskMessage(false);
      this.showFrame(this.currentFrame);
    },

    showFrame(index) {
      if (this.frames[index]) {
        const {
          labels,
          layer,
          layers,
          addEvents,
          mask,
          source_timestamp,
        } = this.frames[index];

        if (layers) {
          layers.forEach((l) => this.showLayer(l));
        }
        else {
          this.showLayer(layer);
        }

        if (labels) {
          [labels].flat().forEach((labelLayer) => {
            if (labelLayer.moveToTop) {
              this.map.moveLayer(labelLayer.id);
            }
            else if (labelLayer.moveOnShow) {
              this.map.moveLayer(labelLayer.id, labelLayer.moveOnShow);
            }

            this.showLayer(labelLayer);
          });
        }

        if (typeof addEvents === 'function') {
          addEvents();
        }

        if (mask) {
          this.showMask(mask);
          // make sure to hide the overlay mask if we have one, this is needed if some of the
          // tilesets have masks and others don't
          if (this.overlayMask) {
            this.hideMask(this.overlayMask);
          }
        // show the overlay mask if we don't have one for this frame
        }
        else if (this.overlayMask) {
          this.showMask(this.overlayMask);
        }

        this.setMapUiProperties({ currentTimestamp: source_timestamp });
        runCoverageCheck(this);
      }
    },

    /**
     * Start an interval to check for state map sources. When an updated map source is found, then
     * update the map frames.
     *
     * The overlay can specify a params object containing a beforeUpdateFrames and/or
     * afterUpdateFrames function. If these functions are present then they will be called before
     * and after the frames are updated. This may be used when the overlay needs to remove event
     * handlers before or add event handlers after adding new frames.
     */
    startStaleCheckInterval(params) {
      this.staleCheckIntervalId = window.setInterval(async () => {
        const wasUpdated = await this.updateMapSourceAndFrames(params);

        // If the map source and frames were updated and the user is viewing the first or last
        // frame then show the current frame since it has changed while they were viewing it.

        const isFirstFrame = this.currentFrame === 0;
        const isLastFrame = this.currentFrame === this.tiles.length - 1;

        if (wasUpdated && (isFirstFrame || isLastFrame)) {
          if (isLastFrame) {
            // When viewing the last frame we have to hide the previous once since it is still
            // visible. However, when viewing the first frame this isn't necessary since the layer
            // will have been removed from the map in removeFrame() when called by updateFrames().
            this.hideFrame(this.tiles.length - 2);
          }

          this.showFrame(this.currentFrame);
        }

        // DEBUG
        let message = `staleCheckInterval(): overlay ${wasUpdated ? 'was' : 'was not'} updated`;

        if (wasUpdated) {
          message = `${message} and first or last frame ${isFirstFrame || isLastFrame ? 'was' : 'was not'} re-rendered.`;
        }
        else {
          message = `${message}.`;
        }

        console.log(message);
      }, 60000);
    },

    tileRangesHrrr(startAt = 0) {
      return [
        { start: startAt, stop: 48, step: 1 },
      ];
    },

    updateFrames() {
      if (this.frames.length) {
        const frameTimestamps = this.frames.map((frame) => frame.source_timestamp);
        const tileTimestamps = this.tiles.map((tile) => tile.source_timestamp);

        const removeframeTimestamps = frameTimestamps.filter((ts) => !tileTimestamps.includes(ts));
        const addTileTimestamps = tileTimestamps.filter((ts) => !frameTimestamps.includes(ts));

        removeframeTimestamps.forEach((timestamp) => {
          const index = this.frames.findIndex((frame) => frame.source_timestamp === timestamp);

          if (index >= 0) {
            this.removeFrame(index);
          }
        });

        addTileTimestamps.forEach((timestamp) => {
          const index = this.tiles.findIndex((t) => t.source_timestamp === timestamp);
          const tile = index > -1 ? this.tiles[index] : null;

          if (tile) {
            this.addFrame(tile, index);
          }
        });
      }
    },

    async updateMapSourceAndFrames(params) {
      let wasUpdated = false;

      try {
        wasUpdated = await this.fetchMapSourcesTiles({ source: this.source });
      }
      catch (e) {
        // Do nothing, if polling will try again in 60s
      }

      if (wasUpdated) {
        if (typeof params?.beforeUpdateFrames === 'function') {
          params.beforeUpdateFrames();
        }

        this.updateFrames();

        if (typeof params?.afterUpdateFrames === 'function') {
          params.afterUpdateFrames();
        }
      }

      return wasUpdated;
    },
  },
};
