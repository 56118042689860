<template>
  <Badge
    :class="$style.container"
    :extra-small="extraSmall"
    text="New"
  />
</template>

<script>
export default {
  name: 'NewBadge',

  props: {
    extraSmall: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style module>
.container {
  background-color: var(--saturated-blue);
  border-color: var(--saturated-navy);
  color: white;
}
</style>
