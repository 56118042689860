<template>
  <transition
    enter-active-class="fade-enter-transition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div
      v-if="showMapCard"
      ref="mapCard"
      v-click-away="handleClickAway"
      class="tw-p-2.5"
    >
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
      >
        <AvalancheForecastCard
          v-if="mapCard.type === mapCardTypes.avalancheForecast"
          :has-body-shadow="true"
          :forecast="mapCard.data.forecast"
          :show-close-button="true"
          @close-button-click="closeMapCard"
        />
        <LocationForecastCard
          v-else-if="mapCard.type === mapCardTypes.locationForecast"
          :has-body-shadow="true"
          :location="mapCard.data.location"
          :show-actions="true"
          :show-close-button="true"
          @close-button-click="closeMapCard"
        />
        <SaveCustomLocationCard
          v-else-if="mapCard.type === mapCardTypes.saveCustomLocation"
          :has-body-shadow="true"
          :location="mapCard.data.location"
          :selected-lists="mapCard.data.selectedLists"
          :show-actions="true"
          :show-close-button="true"
          @close-button-click="closeMapCard"
        />
        <SelectFavoriteListCard
          v-else-if="mapCard.type === mapCardTypes.selectFavoriteList"
          :action="mapCard.data.action"
          :default-selected-lists="mapCard.data.defaultSelectedLists"
          :has-body-shadow="true"
          :in-map-card="true"
          :location="mapCard.data.location"
          @close="closeMapCard"
        />
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { mixin as clickaway } from 'vue3-click-away';
import { mapCardTypes, useMapStore } from '@@/stores/Map';

export default {
  name: 'MapCard',

  mixins: [clickaway],

  computed: {
    ...mapState(useMapStore, { mapCard: (state) => state.ui.mapCard }),

    mapCardTypes() {
      return mapCardTypes;
    },

    showMapCard() {
      return this.mapCard && this.mapCard.type !== mapCardTypes.removeCustomLocationDialog;
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    closeMapCard() {
      this.setMapUiProperties({ mapCard: null });
    },

    handleClickAway() {
      const { activeElement } = document;

      if (activeElement && this.$refs.mapCard && this.$refs.mapCard.contains(activeElement)) {
        // Focus is still in the <MapCard> so don't close it yet!
        return;
      }

      this.closeMapCard();
    },
  },
};
</script>
