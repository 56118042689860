<template>
  <div
    v-if="hasControls"
    :class="containerClass"
  >
    <div :class="controlContainerClass">
      <button
        v-if="canShowToggle3DControl"
        :class="buttonClass"
        @click="handleToggle3DClick"
      >
        <strong>
          {{ is3D ? '2D' : '3D' }}
        </strong>
      </button>
      <button
        v-if="canShowGeolocateControl"
        :class="buttonClass"
        @click="handleGeolocateButtonClick"
      >
        <span
          :class="geolocateIconClass"
          :style="geolocateIconStyle"
        />
      </button>
      <button
        v-if="canShowNavigationControl"
        :class="buttonClass"
        @click="handleZoomInButtonClick"
      >
        <span
          :class="iconClass"
          :style="navigationZoomInIconStyle"
        />
      </button>
      <button
        v-if="canShowNavigationControl"
        :class="buttonClass"
        @click="handleZoomOutButtonClick"
      >
        <span
          :class="iconClass"
          :style="navigationZoomOutIconStyle"
        />
      </button>
      <button
        v-if="canViewFullScreen"
        :class="buttonClass"
        @click="handleFullScreenButtonClick"
      >
        <span
          :class="iconClass"
          :style="fullScreenIconStyle"
        />
      </button>
      <button
        v-if="canShowOpacityControl"
        :class="buttonClass"
        @click="handleOpacityButtonClick"
      >
        <span
          :class="iconClass"
          :style="opacityIconStyle"
        />
      </button>
    </div>
    <OpacityControl />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import { getMaskImageStyle } from '@@/utils/CommonUtils';

export default {
  name: 'MapControlGroup',

  props: {
    canViewFullScreen: {
      type: Boolean,
      default: false,
    },
    controlsToShow: {
      type: Array,
      default: null,
    },
    map: {
      type: Object,
      default: null,
    },
  },

  setup() {
    const { getUserLocation } = useUserLocation();
    return { getUserLocation };
  },

  data() {
    return {
      isDisplayingCurrentPosition: false,
      isGettingCurrentPosition: false,
      userLocationDotMarker: null,
    };
  },

  computed: {
    ...mapState(useMapStore, ['currentOverlay', 'is3D']),

    ...mapState(useMapStore, {
      isFullScreen: (state) => state.ui.isFullScreen,
      showOpacity: (state) => state.ui.showOpacity,
    }),

    buttonClass() {
      return [
        'tw-block tw-w-7 tw-h-7',
        'tw-mx-0.5 tw-my-0.5',
        'tw-rounded-md',
        'tw-appearance-none tw-transition-colors',
        this.$style.button,
      ];
    },

    canShowToggle3DControl() {
      return this.controlsToShow?.includes('Toggle3D');
    },

    canShowGeolocateControl() {
      return this.controlsToShow?.includes('Geolocate');
    },

    canShowNavigationControl() {
      return this.controlsToShow?.includes('Navigation');
    },

    canShowOpacityControl() {
      return this.controlsToShow?.includes('Opacity') && this.currentOverlay;
    },

    containerClass() {
      return 'tw-absolute tw-top-24 tw-right-2.5 tw-z-10 tw-w-8';
    },

    controlContainerClass() {
      return [
        'tw-border tw-rounded-md card-border-color',
        'tw-mb-2.5',
        'tw-shadow-md',
        this.$style.container,
      ];
    },

    fullScreenIconStyle() {
      const iconUrl = this.isFullScreen
        ? 'https://blizzard.opensnow.com/icons/fa/regular/compress-wide.svg'
        : 'https://blizzard.opensnow.com/icons/fa/regular/expand.svg';
      return this.getIconStyle(iconUrl, '65%');
    },

    geolocateIconClass() {
      return [
        this.iconClass,
        this.isGettingCurrentPosition ? 'tw-animate-spin' : null,
        this.$style.geolocateIcon,
        this.isDisplayingCurrentPosition ? this.$style.displayingCurrentPosition : null,
      ];
    },

    geolocateIconStyle() {
      const iconUrl = 'https://blizzard.opensnow.com/icons/fa/regular/location-crosshairs.svg';
      return this.getIconStyle(iconUrl, '70%');
    },

    hasControls() {
      return this.controlsToShow?.length > 0 || this.canViewFullScreen;
    },

    iconClass() {
      return 'tw-inline-block tw-w-full tw-h-full';
    },

    navigationZoomInIconStyle() {
      return this.getIconStyle('https://blizzard.opensnow.com/icons/fa/regular/plus.svg');
    },

    navigationZoomOutIconStyle() {
      return this.getIconStyle('https://blizzard.opensnow.com/icons/fa/regular/minus.svg');
    },

    opacityIconStyle() {
      const iconUrl = 'https://blizzard.opensnow.com/icons/fa/regular/circle-half-stroke.svg';
      const x = this.showOpacity ? 1 : -1;

      return {
        transform: `scaleX(${x})`,
        ...this.getIconStyle(iconUrl),
      };
    },
  },

  mounted() {
    this.addEventListeners();
  },

  beforeUnmount() {
    this.removeUserLocationDotMarker();
    this.removeEventListeners();
  },

  methods: {
    ...mapActions(useMapStore, [
      'setMapLocation',
      'setMapUiProperties',
      'setShowOpacity',
    ]),

    addEventListeners() {
      if (this.canShowToggle3DControl) {
        this.handlePitchDebouce = debounce(this.handlePitch, 100);
        this.map.on('pitch', this.handlePitchDebouce);
      }
    },

    async addUserLocationDotMarker(lngLat) {
      this.removeUserLocationDotMarker();

      const element = document.createElement('div');
      element.classList.add('mapboxgl-user-location');

      const dot = document.createElement('div');
      dot.classList.add('mapboxgl-user-location-dot');
      element.appendChild(dot);

      const options = {
        element,
        pitchAlignment: 'map',
        rotationAlignment: 'map',
      };

      this.userLocationDotMarker = new mapboxgl.Marker(options)
        .setLngLat(lngLat)
        .addTo(this.map);
    },

    getIconStyle(iconUrl, maskSize = '65%') {
      return {
        'backgroundColor': 'currentColor',
        '-webkit-mask-size': maskSize,
        maskSize,
        ...getMaskImageStyle(iconUrl),
      };
    },

    handleFullScreenButtonClick() {
      this.setMapUiProperties({ isFullScreen: !this.isFullScreen });
    },

    async handleGeolocateButtonClick() {
      if (this.isDisplayingCurrentPosition) {
        this.removeUserLocationDotMarker();
        this.isDisplayingCurrentPosition = false;
        return;
      }

      this.isGettingCurrentPosition = true;

      let coordinates;

      try {
        coordinates = await this.getUserLocation();
      }
      catch (e) {
        // The exception getting the user's location is handled below
      }

      this.isGettingCurrentPosition = false;

      if (!coordinates) {
        this.$toast.open({
          message: 'Unable to get current location!',
          type: 'error',
        });
      }
      else {
        const [longitude, latitude] = coordinates;
        this.isDisplayingCurrentPosition = true;
        this.setMapLocation({ lng: longitude, lat: latitude });
        this.addUserLocationDotMarker(coordinates);
      }
    },

    handleOpacityButtonClick() {
      this.setShowOpacity(!this.showOpacity);
    },

    handlePitch() {
      const pitch = this.map.getPitch();
      this.setMapUiProperties({ pitch });
    },

    handleToggle3DClick() {
      const pitch = this.is3D ? 0 : 65;

      this.setMapUiProperties({
        pitch,
        shouldReset3D: true,
      });
    },

    handleZoomInButtonClick() {
      this.map.zoomIn();
    },

    handleZoomOutButtonClick() {
      this.map.zoomOut();
    },

    removeEventListeners() {
      if (this.handlePitchDebouce) {
        this.map.off('pitch', this.handlePitchDebouce);
      }
    },

    removeUserLocationDotMarker() {
      if (this.userLocationDotMarker) {
        this.userLocationDotMarker.remove();
        this.userLocationDotMarker = null;
      }
    },
  },
};
</script>

<style module>
.container {
  background-color: rgb(var(--card-background-rgb) / 75%);
}

.button:hover {
  background-color: var(--button-secondary-background-hover);
}

.geolocateIcon {
  animation-duration: 2s !important;
}

.displayingCurrentPosition {
  /* This is the background-color used for the mapboxgl-user-location-dot in the Mapbox CSS */
  color: #1da1f2;
}
</style>
