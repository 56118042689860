<template>
  <div :class="containerClass">
    <Button
      :display-block="true"
      :to="locationButton.to"
      :type="locationButton.type"
    >
      View Full Forecast
    </Button>
    <Button
      :display-block="true"
      :to="favoriteButton.to"
      :type="favoriteButton.type"
      @click="toggleFavoriteLocation"
    >
      <span>
        <font-awesome-icon
          :icon="favoriteButton.icon"
        />
        {{ favoriteButton.text }}
      </span>
    </Button>
  </div>
</template>

<script>
/* eslint camelcase: off */
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { addIsCustomToPath, getLocationPath } from '@@/utils/CommonUtils';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { mapCardTypes, useMapStore } from '@@/stores/Map';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import FavoritesStorageMixin from '@@/components/Favorites/FavoritesStorageMixin';

/**
 * @todo
 * - Navigate to location when <SnowForecastCard> is clicked.
 */
export default {
  name: 'MapCardActions',

  mixins: [FavoritesStorageMixin],

  props: {
    location: {
      type: Object,
      required: true,
    },
  },

  emits: ['close-button-click'],

  computed: {
    ...mapState(useMetaStore, ['skiAreaLocationTypes']),
    ...mapState(useUserStore, ['isAllAccess', 'isGuest']),
    ...mapState(useUserFavoritesStore, ['isFavoriteLocation']),

    containerClass() {
      return 'tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-2 lg:tw-gap-x-2 lg_tw-gap-y-0 tw-mt-2';
    },

    favoriteButton() {
      const return_to = this.$route.fullPath;

      let text = 'Add to Favorites';
      let to = null;
      let icon = this.isFavorite ? ['fas', 'pencil-alt'] : ['far', 'star'];

      if (this.isFavorite) {
        text = 'Update Favorites';
      }

      if (this.isGuest || (!this.isAllAccess && this.location.is_custom)) {
        to = getStartFreeTrialUrl({
          isGuest: this.isGuest,
          query: { return_to },
        });
        icon = 'lock';
      }

      return {
        icon,
        text,
        to,
        type: ButtonTypes.secondary,
      };
    },

    locationButton() {
      const path = getLocationPath(this.location, this.skiAreaLocationTypes);

      return {
        to: addIsCustomToPath(path, this.location),
        type: ButtonTypes.primary,
      };
    },

    isFavorite() {
      const locationId = this.location.id;
      return this.isFavoriteLocation({ locationId });
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapCard']),

    showRemoveCustomLocationDialog() {
      this.setMapCard({
        data: { location: this.location },
        type: mapCardTypes.removeCustomLocationDialog,
      });
    },

    showSaveCustomLocationCard() {
      this.setMapCard({
        data: { location: this.location },
        type: mapCardTypes.saveCustomLocation,
      });
    },

    showSelectFavoriteListCard(action) {
      const defaultSelectedLists = action === 'update'
        ? this.getFavoriteListsForLocation(this.location)
        : null;

      this.setMapCard({
        data: {
          action,
          defaultSelectedLists,
          location: this.location,
        },
        type: mapCardTypes.selectFavoriteList,
      });
    },

    async toggleFavoriteLocation($event) {
      const { originalEvent } = $event;

      originalEvent.preventDefault();
      originalEvent.stopImmediatePropagation();

      if (this.isGuest) {
        return;
      }

      if (this.isFavorite) {
        this.showSelectFavoriteListCard('update');
        return;
      }

      if (!this.isFavorite && this.location.is_custom) {
        this.showSelectFavoriteListCard('add');
        return;
      }

      if (!this.isFavorite) {
        this.showSelectFavoriteListCard('add');
        return;
      }

      originalEvent.target.blur();

      // Close the map card after removing a custom location
      if (this.location.is_custom) {
        this.$emit('close-button-click');
      }
    },
  },
};
</script>
