import mapboxgl from 'mapbox-gl';

export default {
  data() {
    return {
      marker: null,
    };
  },

  methods: {
    addMarker(lngLat, map) {
      this.removeMarker();

      const options = {
        color: 'var(--saturated-orange)',
        scale: 0.75,
      };

      this.marker = new mapboxgl.Marker(options)
        .setLngLat(lngLat)
        .addTo(map);
    },

    removeMarker() {
      if (this.marker) {
        this.marker.remove();
        this.marker = null;
      }
    },
  },
};
