<template>
  <ul>
    <li
      v-for="{ category, overlays } in overlaysByCategory"
      :key="category.display"
      class="tw-my-2.5"
    >
      <h4
        class="tw-mb-1 lg:tw-mb-2.5 tw-px-1 lg:tw-px-2.5 tw-font-bold tw-text-base"
      >
        {{ category.display }}
      </h4>
      <LayerSelectionControl
        :current-layer="currentOverlay"
        :layers="overlays"
        :show-badges="true"
        :show-lock="true"
        @click="handleOverlayClick"
      />
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from 'pinia';

export default {
  name: 'OverlayControl',

  computed: {
    ...mapState(useMapStore, {
      sources: (state) => state.data.sources,
    }),

    ...mapState(useMapStore, ['currentOverlay', 'visibleCategories', 'visibleOverlays']),

    ...mapState(useUserStore, ['isAllAccess']),

    /**
     * Return an array of { category, overlays } objects mapping the sorted visible overlays to
     * their corresponding sorted visible categories. Categories with no overlays are filtered out.
     * This array is used to list the overlays grouped by category in the UI in the Overlay Tab in
     * the Layer Drawer.
     */
    overlaysByCategory() {
      // NOTE: A shallow copy of visibleCatetories is first created to avoid the unintended side
      // effect of mutating it by sorting it in place!
      // SEE: https://eslint.vuejs.org/rules/no-side-effects-in-computed-properties.html

      return [...this.visibleCategories]
        .sort((a, b) => a.sort_weight - b.sort_weight)
        .map((category) => {
          const overlays = this.visibleOverlays
            .filter((overlay) => overlay.category_id === category.id)
            .sort((a, b) => a.sort_weight - b.sort_weight);
          return { category, overlays };
        })
        .filter(({ overlays }) => overlays.length > 0);
    },
  },

  methods: {
    ...mapActions(useMapStore, [
      'setMapUiProperties',
    ]),

    async handleOverlayClick(shortName) {
      if (this.currentOverlay && this.currentOverlay.short_name === shortName) {
        this.setMapUiProperties({ currentOverlay: null });
      }
      else {
        this.setMapUiProperties({ currentOverlay: null });
        await this.$nextTick();
        this.setMapUiProperties({ currentOverlay: shortName });
      }
    },
  },
};
</script>
