import { mapActions, mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';

/**
 * The OverlayMixin contains common functionality used by Overlays. The component that uses this
 * mixin is required to define the source data property so that map tiles can be retrieved from
 * OpenMountian-API GET /maps/sources.
 */
export default {
  props: {
    isMapLoaded: {
      type: Boolean,
      required: true,
    },
    isMiniMap: {
      type: Boolean,
      default: false,
    },
    map: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapState(useMapStore, {
      categories: (state) => state.data.categories,
      mapStyle: (state) => state.style,
      sources: (state) => state.data.sources,
    }),

    ...mapState(useMapStore, ['currentBaseMap', 'currentOverlay']),

    isActive() {
      if (this.currentOverlay
        && this.currentOverlay.short_name === this.shortName
        && this.map
        && this.isMapLoaded) {
        return true;
      }

      return false;
    },

    mapCategory() {
      return this.categories.find((category) => category.id === this.mapSource.category_id);
    },

    mapSource() {
      return this.sources[this.source.short_name];
    },

    shortName() {
      return this.source.short_name;
    },

    tiles() {
      return this.mapSource.tiles[this.source.tile_types[0]];
    },
  },

  /**
   * Overlays are renderless components that doesn't output any HTML. Instead they use the map, a
   * mapbox-gl instance, for rendering. Overlay components _are_ reactive however since they will
   * render content on the map when data/props/state change.
   */
  render() {
    return this.$slots.default;
  },

  mounted() {
    this.checkComponent();
  },

  watch: {
    isActive(value) {
      if (value) {
        this.add();
        this.setMapUiProperties({ isForecast: !!this.isForecast });
      }
      else {
        this.hide();
        this.setMapUiProperties({ isForecast: null });
      }
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    /**
     * Verify required fields are present on component.
     */
    checkComponent() {
      if (!this.source) {
        throw new Error(`The ${this._name || ''} component that uses the OverlayMixin must specify the tile source in its data!`);
      }

      if (!this.layers && !this.frames) {
        throw new Error(`The ${this._name || ''} component that uses the OverlayMixin must declare the layers or frames array in its data!`);
      }
    },
  },
};
