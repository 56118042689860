<template>
  <Card
    :has-body-shadow="hasBodyShadow"
    :show-close-button="showCloseButton"
    @close-button-click="$emit('close-button-click')"
  >
    <template #body>
      <AvalancheForecast
        :forecast="forecast"
        :in-map-card="inMapCard"
        :location="location"
      />
    </template>
  </Card>
</template>

<script>
export default {
  name: 'AvalancheForecastCard',

  props: {
    hasBodyShadow: {
      type: Boolean,
      default: false,
    },
    forecast: {
      type: Object,
      required: true,
    },
    inMapCard: {
      type: Boolean,
      default: true,
    },
    location: {
      type: Object,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-button-click'],
};
</script>
