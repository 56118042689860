import { mapActions, mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';
import {
  addFill, addLabels, addMask, addSource, runCoverageCheck, coverageBbox,
} from '../Utils';

export default {
  computed: {
    ...mapState(useMapStore, {
      center: (state) => state.ui.center,
    }),
  },
  watch: {
    center() {
      if (this.isActive) {
        runCoverageCheck(this);
      }
    },
  },
  methods: {
    ...mapActions(useMapStore, [
      'fetchMapSourcesTiles',
      'setShowMaskMessage',
      'setMaskMessage',
      'setOverlayCoverageBbox',
    ]),

    async add() {
      await this.checkForUpdatedMapSource();

      if (this.layers.length > 0) {
        this.show();
        // TODO: Fetch fresh tiles here or maybe do that in the watch after calling add()?
        return;
      }

      const [tile] = this.tiles;

      this.addSource({ tile });
      this.addFill({ tile });
      this.addLabels({ tile });
      this.addMasks({ tile });
      this.show();
    },

    addLabels(params = {}) {
      this.layers.push(addLabels(params, this));
    },

    addFill(params = {}) {
      this.layers.push(addFill(params, this, this.currentBaseMap));
    },

    addSource(params = {}) {
      this.mapSourceId = addSource(params, this);
    },

    addMasks(params = {}) {
      // add the mask from the map tileset
      const tilesetMask = addMask(params, this);
      if (tilesetMask) {
        this.activeMask = tilesetMask;
        tilesetMask.layers.forEach((l) => this.layers.push(l));
        if (!this.maskSourceIds) {
          this.maskSourceIds = tilesetMask.sources;
        }
        else {
          tilesetMask.sources.forEach((l) => this.maskSourceIds.push(l));
        }
        // if we have one for the tileset, we don't need one for the overlay as a whole
        return;
      }
      const { geomOnly = false } = params;
      const overlayMask = addMask({ geomOnly }, this);
      if (overlayMask) {
        this.activeMask = overlayMask;
        overlayMask.layers.forEach((l) => this.layers.push(l));
        if (!this.maskSourceIds) {
          this.maskSourceIds = overlayMask.sources;
        }
        else {
          overlayMask.sources.forEach((l) => this.maskSourceIds.push(l));
        }
      }
    },

    hide() {
      this.layers.forEach((layer) => this.map.setLayoutProperty(layer.id, 'visibility', 'none'));
    },

    remove() {
      if (typeof this.handleRemove === 'function') {
        this.handleRemove();
      }

      if (typeof this.removeEvents === 'function') {
        this.removeEvents();
      }

      this.layers.forEach((layer) => {
        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }
      });

      if (this.mapSourceId && this.map.getSource(this.mapSourceId)) {
        this.map.removeSource(this.mapSourceId);
      }

      (this.maskSourceIds ?? []).forEach((sourceId) => {
        if (this.map.getSource(sourceId)) {
          this.map.removeSource(this.sourceId);
        }
      });

      this.layers.splice(0);
    },

    showLayer(l, forceOpacityValue = null) {
      l.opacities?.forEach((opacity, idx) => {
        // allow layers to set opacities independently for each opacity property
        // and allow more complex expressions or functions to be used in addition
        // to numeric values between 0 and 1
        const opacityValue = (forceOpacityValue ?? l.opacityValues?.[idx]) ?? null;

        if (opacityValue === null) {
          return this.map.setPaintProperty(l.id, opacity, this.overlayOpacity);
        }

        return this.map.setPaintProperty(l.id, opacity, opacityValue instanceof Function
          ? opacityValue(this.overlayOpacity)
          : opacityValue);
      });
      this.map.setLayoutProperty(l.id, 'visibility', 'visible');
    },

    show() {
      this.layers.forEach((layer) => {
        this.showLayer(layer);
      });

      this.setMapUiProperties({ currentTimestamp: this.tiles[0].source_timestamp });

      this.setShowMaskMessage(false);
      if (this.activeMask) {
        this.setOverlayCoverageBbox(coverageBbox(this.activeMask));
        runCoverageCheck(this);
      }
    },

    async checkForUpdatedMapSource() {
      const wasUpdated = await this.fetchMapSourcesTiles({ source: this.source });

      if (wasUpdated) {
        this.remove();
      }
    },

  },
};
