<template>
  <div
    v-if="canShowCompassControl"
    :class="containerClass"
  >
    <button
      :class="buttonClass"
      @click="handleCompassButtonClick"
    >
      <CompassIcon
        :class="iconClass"
        :style="iconStyle"
      />
    </button>
  </div>
</template>

<script>
import { mapActions, mapStores } from 'pinia';
import { throttle } from 'lodash';
import { useMapStore } from '@@/stores/Map';
import { bearing360 } from '@@/utils/MapUtils';
import CompassIcon from '~/assets/svg/compass.svg?skipsvgo';

export default {
  name: 'CompassControl',

  components: {
    CompassIcon,
  },

  props: {
    map: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapStores(useMapStore),

    bearing: {
      get() {
        return this.mapStore.ui.bearing;
      },

      set(bearing) {
        this.setMapUiProperties({ bearing });
      },
    },

    buttonClass() {
      return [
        'tw-block tw-w-7 tw-h-7',
        'tw-mx-0.5 tw-my-0.5',
        'tw-rounded-full',
        'tw-appearance-none tw-transition-colors',
        this.$style.button,
      ];
    },

    canShowCompassControl() {
      return true;
    },

    containerClass() {
      return [
        'tw-absolute tw-top-14 tw-right-2.5 tw-z-10 tw-w-8',
        'tw-border tw-rounded-full card-border-color',
        'tw-mb-2.5',
        'tw-shadow-md',
        this.$style.container,
      ];
    },

    iconClass() {
      return [
        'tw-inline-block',
        'tw-w-full tw-h-full',
        'tw--ml-0.5',
        'tw-fill-current',
        'tw-transition-transform',
      ];
    },

    iconStyle() {
      const rotate = parseInt(this.bearing * -1, 10);
      return !this.map ? null : { transform: `rotate(${rotate}deg)` };
    },
  },

  mounted() {
    this.addEventListeners();
  },

  beforeUnmount() {
    this.removeEventListeners();
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    addEventListeners() {
      this.handleMapMoveThrottle = throttle(this.handleMapMove, 100);
      this.map.on('move', this.handleMapMoveThrottle);
    },

    handleCompassButtonClick() {
      this.map.easeTo({ bearing: 0 });
    },

    handleMapMove() {
      this.bearing = bearing360(this.map.getBearing());
    },

    removeEventListeners() {
      this.map.off('move', this.handleMapMoveThrottle);
    },
  },
};
</script>

<style module>
.container {
  background-color: rgb(var(--card-background-rgb) / 75%);
}

.button:hover {
  background-color: var(--button-secondary-background-hover);
}
</style>
