<template>
  <div
    id="controlBar"
    :class="containerClass"
    @click="setIsLayerDrawerOpen(!isLayerDrawerOpen)"
  >
    <div class="tw-flex tw-h-full">
      <div
        id="overlayDetails"
        :class="overlayDetailsClass"
      >
        <div class="tw-w-full md:tw-w-auto tw-flex tw-justify-between tw-items-center md:tw-mr-4">
          <p>
            <strong>{{ overlayText.title }}</strong>
            <span
              v-if="overlayText.subtitle"
            >
              {{ overlayText.subtitle }}
            </span>
            <EstimatedBadge
              v-if="isCurrentOverlayEstimated"
              v-tooltip="estimatedTooltip"
              class="tw-ml-1 tw-cursor-default"
              :extra-small="true"
            />
            <OverlayTimestamp
              :class="overlayTimestampClass"
            />
          </p>
          <NuxtLink
            v-if="supportUrl"
            class="tw-inline-block md:tw-ml-4 tw-leading-none"
            :href="supportUrl"
            target="_blank"
            @click.stop
          >
            <font-awesome-icon
              class="text-dark-color"
              :icon="['far', 'info-circle']"
            />
          </NuxtLink>
        </div>
        <div
          id="animationControlContainer"
          :class="animationControlContainerClass"
          @click="handleAnimationControlClick"
        >
          <AnimationControl />
        </div>
      </div>
      <div :class="layerSelectionClass">
        <span
          class="tw-block tw-w-5 tw-h-5"
          :style="layerSelectionIconStyle"
        />
      </div>
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
      >
        <LoadingIndicator
          v-if="showLoadingIndicator"
          class="tw-absolute tw-z-30"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { getMaskImageStyle } from '@/utils/CommonUtils';

export default {
  name: 'ControlBar',

  computed: {
    ...mapState(useMapStore, {
      isLayerDrawerOpen: (state) => state.ui.isLayerDrawerOpen,
      isMapIdle: (state) => state.ui.isMapIdle,
      showAnimationControl: (state) => state.ui.showAnimationControl,
      showLocations: (state) => state.ui.showLocations,
    }),

    ...mapState(useMapStore, [
      'currentOverlay',
      'visibleOverlays',
    ]),

    animationControlContainerClass() {
      return [
        'tw-w-full md:tw-w-auto md:tw-h-full',
        'md:tw-flex-grow md:tw-flex md:tw-items-center',
        this.currentOverlay && this.showAnimationControl ? 'tw-cursor-default' : null,
      ];
    },

    containerClass() {
      const containerClass = this.isLayerDrawerOpen
        ? 'card-background-color'
        : this.$style.container;

      return [
        'tw-relative',
        'tw-h-16 lg:tw-h-14',
        'tw-border-solid tw-border-t',
        'tw-cursor-pointer',
        'tw-transition-colors tw-ease-in-out tw-duration-200',
        'border-color',
        containerClass,
      ];
    },

    estimatedTooltip() {
      if (this.isCurrentOverlayEstimated) {
        return 'Estimates are based on limited on-the-ground observations and model data, and may improve over time as more input data is processed.';
      }

      return null;
    },

    isCurrentOverlayEstimated() {
      return this.currentOverlay?.is_estimated === true;
    },

    layerSelectionClass() {
      return [
        'tw-flex tw-justify-center tw-items-center',
        'tw-w-16',
        'tw-border-l border-color',
      ];
    },

    layerSelectionIconStyle() {
      return {
        backgroundColor: 'currentColor',
        ...getMaskImageStyle('https://blizzard.opensnow.com/icons/fa/regular/layer-group.svg'),
      };
    },

    overlayDetailsClass() {
      return [
        'tw-flex tw-flex-col md:tw-flex-row',
        'tw-items-start md:tw-items-center',
        this.currentOverlay && this.showAnimationControl
          ? 'tw-justify-start md:tw-justify-center'
          : 'tw-justify-center',
        'tw-flex-grow',
        'tw-px-4',
      ];
    },

    overlayText() {
      if (this.currentOverlay) {
        return {
          title: this.currentOverlay.display,
        };
      }

      return {
        subtitle: `(${this.visibleOverlays.length} available)`,
        title: 'None',
      };
    },

    overlayTimestampClass() {
      return `tw--mt-1 tw-whitespace-nowrap ${this.$style.overlayTimestamp}`;
    },

    showLoadingIndicator() {
      return this.currentOverlay && this.showAnimationControl && this.isMapIdle === false;
    },

    supportUrl() {
      return this.currentOverlay?.support_url;
    },
  },

  methods: {
    ...mapActions(useMapStore, [
      'setIsLayerDrawerOpen',
      'setMapUiProperties',
    ]),

    handleAnimationControlClick(event) {
      if (this.currentOverlay && this.showAnimationControl) {
        event.stopPropagation();
      }
    },

    setSelectedLayerDrawerTab(selectedLayerDrawerTab) {
      this.setMapUiProperties({ selectedLayerDrawerTab });
    },
  },
};
</script>

<style module>
.container {
  background-color: rgb(var(--card-background-rgb) / 75%);
}

@media (min-width: 992px) {
  .overlayTimestamp {
    min-width: 10rem;
  }
}
</style>
