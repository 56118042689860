<template>
  <li
    :class="containerClass"
    @click="$emit('click');"
  >
    <slot />
  </li>
</template>

<script>
export default {
  name: 'LayerSelectionItem',

  emits: ['click'],

  computed: {
    containerClass() {
      return [
        'tw-flex',
        'tw-py-2.5 tw-px-1 lg:tw-px-2.5 lg:tw-mr-2.5',
        'tw-cursor-pointer',
        this.$style.selectionWrapper,
      ];
    },
  },
};
</script>

<style module>
.selectionWrapper {
  border-bottom: 1px solid var(--border-color);
  width: 100%;
}

.selectionWrapper:nth-last-child(1) {
  border-bottom: 0;
}

@media (min-width: 992px) {
  .selectionWrapper {
    width: calc(50% - 2.5rem);
  }

  .selectionWrapper:nth-last-child(2) {
    border-bottom: 0 none;
  }
}
</style>
