<template>
  <transition
    :enter-active-class="$style.enterTransition"
    enter-from-class="fade-out"
    leave-active-class="fade-leave-transition"
    leave-to-class="fade-out"
  >
    <div
      v-if="currentOverlay && showOpacity"
      :class="containerClass"
    >
      <div :class="contentClass">
        <RangeInput
          input-width="10.75rem"
          :max="1"
          :min="0.05"
          :step="0.01"
          translate-x="-100%"
          :translate-y="translateY"
          :model-value="opacity"
          :vertical="true"
          @update:model-value="(opacity) => setMapUiProperties({ opacity })"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'pinia';

export default {
  name: 'OpacityControl',

  computed: {
    ...mapState(useMapStore, ['currentOverlay']),

    ...mapState(useMapStore, {
      opacity: (state) => state.ui.opacity,
      showOpacity: (state) => state.ui.showOpacity,
    }),

    containerClass() {
      return 'tw-w-8 tw-h-48';
    },

    contentClass() {
      return [
        'tw-relative',
        'tw-w-full tw-h-full tw-px-2.5',
        'tw-opacity-80',
        'tw-rounded-md',
        'card-background-color text-dark-color',
      ];
    },

    /**
     * HACK: It's not clear _why_ Firefox requires a different value and sign for the translate-y
     * value. But due to bug fixing fatigue, this hack will be used since these values are seen to
     * render the vertical range slider properly in Firefox, Chrome, and Safari.
     */
    translateY() {
      return window?.navigator?.userAgent?.includes('Firefox') ? '-20%' : '100%';
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),
  },
};
</script>

<style module>
.enterTransition {
  transition: opacity var(--fade-transition-time) ease-in-out;
}
</style>
