<template>
  <div
    :class="containerClass"
    :style="{
      /* stylelint-disable custom-property-pattern */
      '--avalancheForecastHeight': avalancheForecastHeight,
    }"
  >
    <div :class="scrollContainerClass">
      <div>
        <h2 class="tw-px-5 tw-pt-5 tw-pb-2.5">
          <span class="tw-block tw-text-xl tw-font-medium heading-color">
            {{ inMapCard ? forecast.name : 'Avalanche Rating' }}
          </span>
        </h2>
        <div v-if="details">
          <h3
            :class="dangerHeaderClass"
            :style="{ backgroundColor: details.danger_color }"
          >
            <span
              v-if="inMapCard"
              class="tw-block tw-text-xs"
            >
              AVALANCHE DANGER
            </span>
            <div class="tw-text-2xl lg:tw-text-3xl">
              <strong class="tw-font-semibold">
                {{ details.danger_label }} ({{ details.danger_level }})
              </strong>
            </div>
            <img
              class="tw-absolute tw-right-4 tw-h-16 lg:tw-h-24 tw--top-1.5 lg:tw--top-3"
              :src="details.danger_icon_url"
            >
          </h3>
          <h4
            v-if="isOld"
            class="tw-py-3 tw-px-5 tw-font-normal tw-bg-gray-200"
          >
            <span class="tw-block">
              <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
              Warning! This is an <strong>outdated</strong> forecast.
            </span>
          </h4>
          <div
            :class="details.is_most_recent ? 'tw-p-5' : 'tw-px-5 tw-pt-3 tw-pb-5'"
          >
            <div class="tw-text-xs tw-mb-2.5">
              <span>Valid {{ validTime }}</span>
              <small
                :class="validTimeAgoClass"
              >
                {{ validTimeAgo }}
              </small>
              <span class="tw-block tw-mt-1">
                Until {{ untilTime }}
              </span>
            </div>
            <div
              :class="['tw-mb-4', $style.summaryText]"
              v-html="details.summary"
            />
            <div
              v-if="!inMapCard"
              class="tw-mb-4"
            >
              <h4 :class="summaryHeaderClass">
                More Detail
              </h4>
              <div :class="$style.summaryText">
                <p>
                  {{ moreDetailText }} please view the
                  <a
                    class="hover:tw-underline link-color-brand"
                    :href="details.forecast_url"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {{ forecast.name }} forecast
                  </a>
                  provided by {{ isCanada ? '' : 'the ' }}
                  <a
                    class="hover:tw-underline link-color-brand"
                    :href="forecast.center_url"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {{ forecast.center }}
                  </a>.
                </p>
              </div>
            </div>
            <div
              v-if="details.snowpack_summary"
              class="tw-mb-4"
            >
              <h4 :class="summaryHeaderClass">
                Snowpack Discussion
              </h4>
              <div
                :class="$style.summaryText"
                v-html="details.snowpack_summary"
              />
            </div>
            <div
              v-if="details.avalanche_summary"
              class="tw-mb-4"
            >
              <h4 :class="summaryHeaderClass">
                Avalanche Activity
              </h4>
              <div
                :class="$style.summaryText"
                v-html="details.avalanche_summary"
              />
            </div>
            <div
              v-if="!inMapCard"
              class="tw-mb-4"
            >
              <h4 :class="summaryHeaderClass">
                Helpful Links
              </h4>
              <div :class="$style.summaryText">
                <ul>
                  <li class="tw-mb-2.5">
                    <a
                      class="tw-font-bold hover:tw-underline link-color-brand"
                      :href="details.forecast_url"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {{ forecast.name }} Forecast
                      <font-awesome-icon icon="arrow-alt-circle-right" />
                    </a>
                  </li>
                  <li class="tw-mb-2.5">
                    <a
                      class="tw-font-bold hover:tw-underline link-color-brand"
                      :href="forecast.center_url"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {{ forecast.center }}
                      <font-awesome-icon icon="arrow-alt-circle-right" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="!inMapCard"
              :class="[
                'tw-relative tw-w-full tw-mb-4',
                'card-background-color',
                $style.mapSnapshot,
              ]"
            >
              <Map
                v-if="mapProps"
                v-bind="mapProps"
                @unable-to-load-map="handleUnableToLoadMap"
              />
            </div>
            <div
              v-if="!inMapCard && details.map_snapshot && !unableToLoadMap"
            >
              <Button
                :display-block="true"
                :type="ButtonTypes.secondary"
                :to="mapUrl"
              >
                View on Map
                <font-awesome-icon
                  class="tw-ml-1 action-button-icon"
                  :icon="['fad', 'map-location-dot']"
                />
              </Button>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="tw-px-5 tw-pb-5 tw-font-normal">
            Forecast not available.
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="details && inMapCard"
      :class="footerClass"
    >
      <Button
        :display-block="true"
        :to="avalancheUrl"
        :type="ButtonTypes.primary"
      >
        {{ forecast.name }} Forecast
        <font-awesome-icon icon="arrow-alt-circle-right" />
      </Button>
    </div>
  </div>
</template>

<script>
/* eslint vue/no-v-html: off */
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { dataNotAvailable, isOld, isScreenSm } from '@@/utils/CommonUtils';
import { useMapStore } from '@@/stores/Map';
import { useUserStore } from '@@/stores/User';
import AvalancheOverlay from '@@/components/Maps/Overlays/AvalancheOverlay.vue';
import LocationsOverlay from '@@/components/Maps/Overlays/LocationsOverlay.vue';
import OverlayNames from '@@/components/Maps/Overlays/OverlayNames';

export default {
  name: 'AvalancheForecast',

  props: {
    forecast: {
      type: Object,
      required: true,
    },
    inMapCard: {
      type: Boolean,
      default: true,
    },
    location: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      mapProps: null,
      unableToLoadMap: false,
    };
  },

  computed: {
    ...mapState(useUserStore, {
      theme: (state) => state.preferences.theme,
    }),

    ButtonTypes() {
      return ButtonTypes;
    },

    avalancheForecastHeight() {
      return this.details ? '20rem' : '12rem';
    },

    avalancheUrl() {
      return `/avalanche/${this.forecast?.slug || ''}`;
    },

    baseMap() {
      if (this.inMapCard) {
        return null;
      }

      return this.theme === 'dark' ? 'dark' : 'terrain';
    },

    dangerHeaderClass() {
      return [
        'tw-relative',
        'tw-px-5',
        this.inMapCard ? 'tw-py-1 lg:tw-py-2.5' : 'tw-py-2.5',
        this.$style.dangerHeader,
      ];
    },

    containerClass() {
      return this.inMapCard ? this.$style.avalancheForecast : null;
    },

    details() {
      return this.forecast.forecasts[0];
    },

    footerClass() {
      return [
        'tw-w-full tw-p-2 tw-h-14',
        'tw-border-t border-color',
        'card-background-color',
      ];
    },

    isCanada() {
      return this.forecast?.country_code === 'CA';
    },

    isOld() {
      return this.details?.display_at ? isOld(this.details.display_at) : undefined;
    },

    mapUrl() {
      const url = new URL(this.details.map_snapshot.share_url);
      return url.toString().split(url.host)[1];
    },

    moreDetailText() {
      return this.isCanada
        ? 'To get the complete forecast with additional graphics and details, '
        : 'The danger rating shown on the map is just one piece of the avalanche forecast. To get the complete forecast, ';
    },

    scrollContainerClass() {
      return this.inMapCard ? this.$style.scrollContainer : null;
    },

    summaryHeaderClass() {
      return 'tw-text-xl tw-mb-2 tw-font-medium';
    },

    validTime() {
      return this.details?.display_at_local_label || dataNotAvailable;
    },

    validTimeAgo() {
      const dateJs = this.getDateInTimezone(this.details.display_at);
      return dateJs.timeAgo();
    },

    validTimeAgoClass() {
      return this.isOld ? 'text-saturated-red-color' : null;
    },

    untilTime() {
      return this.details?.display_until_local_label || dataNotAvailable;
    },
  },

  watch: {
    async baseMap(newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        await this.setCurrentBaseMap(this.baseMap);
      }
    },
  },

  mounted() {
    if (!this.inMapCard) {
      this.mapProps = {
        baseMap: this.baseMap,
        bounds: this.forecast?.geo_stats.bbox,
        canBeFullScreen: false,
        center: this.forecast?.geo_stats.centroid,
        controlsToShow: ['Navigation'],
        currentOverlay: OverlayNames.avalanche,
        overlays: [
          markRaw(AvalancheOverlay),
          markRaw(LocationsOverlay),
        ],
        overlayProps: {
          AvalancheOverlay: {
            bounds: this.forecast?.geo_stats.bbox,
            isLocationAvalancheForecast: this.location !== null,
            isMiniMap: true,
            shouldFitBounds: true,
          },
          LocationsOverlay: { isMiniMap: true, location: this.location },
        },
        zoom: isScreenSm() ? 7 : 9,
      };
    }
  },

  methods: {
    ...mapActions(useMapStore, ['setCurrentBaseMap']),

    formatDateTime(dt) {
      const dateJs = this.getDateInTimezone(dt);
      return dateJs.format('dddd MMM Do h:mm a z');
    },

    getDateInTimezone(dt) {
      const { timezone } = this.forecast;
      return this.$dayjs.utc(dt).tz(timezone);
    },

    handleUnableToLoadMap() {
      this.unableToLoadMap = true;
    },
  },
};
</script>

<style module>
/* stylelint-disable custom-property-pattern */

.avalancheForecast {
  max-height: var(--avalancheForecastHeight);
}

@media (min-width: 992px) {
  .avalancheForecast {
    max-height: calc(var(--avalancheForecastHeight) + 5rem);
  }
}

.scrollContainer {
  max-height: calc(var(--avalancheForecastHeight) - 3.5rem);
  overflow-y: auto;
}

@media (min-width: 992px) {
  .scrollContainer {
    max-height: calc(var(--avalancheForecastHeight) + 5rem - 3.5rem);
  }
}

.dangerHeader {
  border-bottom: 1px solid var(--text-dark);
  border-top: 1px solid var(--text-dark);
  color: black;
}

.summaryText p {
  margin-bottom: 0.625rem;
}

.summaryText a {
  color: var(--brand-link-color);
}

.summaryText a:hover {
  text-decoration: underline;
}

.mapSnapshot {
  aspect-ratio: 4/3;
}
</style>
