<template>
  <Badge
    :class="$style.container"
    text="Loopable"
  />
</template>

<script>
export default {
  name: 'LoopableBadge',
};
</script>

<style module>
.container {
  background-color: white;
  border-color: var(--text-dark);
  color: var(--text-dark);
}

:global([data-theme="dark"]) .container,
:global([data-color-theme="dark"]) .container {
  background-color: black;
}
</style>
