import bbox from '@turf/bbox';
import { isScreenSm } from '@@/utils/CommonUtils';

export const createFeatureCollection = (locations, addProperties = () => ({})) => {
  const features = locations?.length
    ? locations.map((location) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: location.coordinates.point,
        },
        properties: {
          id: location.id,
          name: location.name,
          shortname: location.slug,
          slug: location.slug,
          type_id: location.type_id,
          ...addProperties(location),
        },
      }))
    : [];

  return {
    type: 'FeatureCollection',
    features,
  };
};

export const fitBoundsToFeatureCollection = (featureCollection, map) => {
  const bounds = bbox(featureCollection);
  const padding = isScreenSm() ? 10 : 100;
  map.fitBounds(bounds, { maxZoom: 10, padding });
};

/**
 * Convert bearing to 0-360 degrees
 *   mapbox map.getBearing() returns bearing as -180 to 180,
 *   mapbox map.setBearing() accepts -180 to 180, OR 0 to 360
 *   the mobile sdks all expect positive integer 0 to 360, so we will convert it here,
 *   to keep URLs consistent with values between 0 and 360
 *
 * @param {number} bearing
 * @returns {number}
 */
export const bearing360 = (bearing) => {
  if (bearing === undefined || bearing === null) {
    return 0;
  }

  // Math.abs to ensure -0 is returned as 0
  return Math.abs(Math.round(bearing < 0 ? bearing + 360 : bearing));
};
