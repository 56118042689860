<template>
  <div class="tw-mb-4">
    <div v-if="canFilterByFavorites">
      <h4 class="tw-pl-1 lg:tw-pl-2 tw-font-bold tw-text-lg">
        Your Favorites
      </h4>
      <p class="lg:tw-mb-1 tw-pl-1 lg:tw-pl-2 tw-text-base tw-font-medium text-dark-color">
        Choose which favorites list(s) to display
      </p>
      <ul class="tw-mb-4 tw-flex tw-flex-wrap">
        <LayerSelectionItem
          v-for="list in lists"
          :key="list.id"
        >
          <label>
            <Checkbox
              class="tw-align-middle tw-mr-2"
              :model-value="isFavoriteListChecked(list.id)"
              @update:model-value="(checked) => updateVisibleFavoriteLists(list.id, checked)"
            />
            {{ list.name }}
            <span class="text-regular-color">
              ({{ getFavoriteListCount(list.id) }})
            </span>
          </label>
        </LayerSelectionItem>
      </ul>
    </div>
    <h4 class="tw-pl-1 lg:tw-pl-2 tw-font-bold tw-text-lg">
      Location Types
    </h4>
    <p class="lg:tw-mb-1 tw-pl-1 lg:tw-pl-2 tw-text-base tw-font-medium text-dark-color">
      Choose the location types to display
    </p>
    <ul class="tw-mb-2 tw-flex tw-flex-wrap">
      <LayerSelectionItem
        v-for="locationType in location_types_maps"
        :key="locationType.id"
      >
        <label>
          <Checkbox
            class="tw-align-middle tw-mr-2"
            :model-value="visibleLocationTypes.includes(locationType.slug)"
            @update:model-value="(checked) => updateVisibleLocationTypes(locationType, checked)"
          />
          {{ locationType.name }}
          <span class="text-regular-color">
            ({{ getLocationTypeCount(locationType.slug) }})
          </span>
        </label>
      </LayerSelectionItem>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

export default {
  name: 'LocationControl',

  computed: {
    ...mapState(useMapStore, {
      visibleFavoriteLists: (state) => state.ui.visibleFavoriteLists,
      visibleLocationTypes: (state) => state.ui.visibleLocationTypes,
    }),

    ...mapState(useMetaStore, ['location_types_maps']),

    ...mapState(useUserFavoritesStore, {
      favoriteIds: (state) => state.user?.favoriteIds,
      lists: (state) => state.user?.lists,
    }),

    ...mapState(useUserStore, ['isGuest']),

    anyVisibleLocationTypesSelected() {
      return this.visibleLocationTypes.length > 0;
    },

    canFilterByFavorites() {
      return this.lists?.length && !this.isGuest;
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    getFavoriteListCount(id) {
      if (!this.favoriteIds?.lists?.length) {
        return 0;
      }

      const list = this.favoriteIds.lists.find(({ list_id }) => list_id === id);

      if (!list?.location_ids?.length) {
        return 0;
      }

      return Number(list.location_ids.length).toLocaleString('en-us');
    },

    getLocationTypeCount(slug) {
      /* eslint camelcase: off */
      const { count_locations } = this.location_types_maps
        .find((locationType) => locationType.slug === slug);
      return Number(count_locations).toLocaleString('en-US');
    },

    isFavoriteListChecked(listId) {
      return this.visibleFavoriteLists && this.visibleFavoriteLists.includes(listId);
    },

    /**
     * Update the visible favorite lists:
     *
     * - If a list was selected then add it to the visible favorite lists array
     * - If a list was unselected then remove it from the visible favorite lists array
     * - If the visible favorite lists array is empty then set it to null
     */
    updateVisibleFavoriteLists(listId, checked) {
      let visibleFavoriteLists = this.visibleFavoriteLists ? [...this.visibleFavoriteLists] : [];

      if (checked && !visibleFavoriteLists.includes(listId)) {
        visibleFavoriteLists.push(listId);
      }
      else if (!checked && visibleFavoriteLists.includes(listId)) {
        const index = visibleFavoriteLists.indexOf(listId);
        visibleFavoriteLists.splice(index, 1);
      }

      if (visibleFavoriteLists.length === 0) {
        visibleFavoriteLists = null;
      }

      this.setMapUiProperties({ visibleFavoriteLists });
    },

    updateVisibleLocationTypes(locationType, selected) {
      const visibleLocationTypes = [...this.visibleLocationTypes];
      const index = visibleLocationTypes.indexOf(locationType.slug);

      if (selected && index === -1) {
        visibleLocationTypes.push(locationType.slug);
      }
      else if (!selected && index !== -1) {
        visibleLocationTypes.splice(index, 1);
      }

      this.setMapUiProperties({ visibleLocationTypes });
    },
  },
};
</script>
