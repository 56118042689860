import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 360 360"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(".deg{height:37px;width:11px}")
      ])),
      _: 1
    })),
    _cache[1] || (_cache[1] = _createStaticVNode("<rect class=\"deg\" style=\"transform:translate(180px,0);\"></rect><rect class=\"deg\" style=\"transform:translate(248px,14px) rotate(22.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(307px,53px) rotate(45deg);\"></rect><rect class=\"deg\" style=\"transform:translate(346px,111px) rotate(67.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(360px,180px) rotate(90deg);\"></rect><rect class=\"deg\" style=\"transform:translate(346px,249px) rotate(112.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(307px,307px) rotate(135deg);\"></rect><rect class=\"deg\" style=\"transform:translate(248px,346px) rotate(157.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(180px,360px) rotate(180deg);\"></rect><rect class=\"deg\" style=\"transform:translate(111px,346px) rotate(202.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(53px,307px) rotate(225deg);\"></rect><rect class=\"deg\" style=\"transform:translate(14px,249px) rotate(247.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(0,180px) rotate(270deg);\"></rect><rect class=\"deg\" style=\"transform:translate(14px,111px) rotate(292.5deg);\"></rect><rect class=\"deg\" style=\"transform:translate(53px,53px) rotate(315deg);\"></rect><rect class=\"deg\" style=\"transform:translate(111px,14px) rotate(337.5deg);\"></rect><path fill=\"red\" d=\"m140 156 46-91 47 91z\"></path><text x=\"132\" y=\"280\" font-family=\"Clear Sans, sans-serif\" font-size=\"150\" textLength=\"100\">N</text>", 18))
  ]))
}
export default { render: render }