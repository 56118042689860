export default {
  airqual: 'airqual',
  airqualf: 'airqualf',
  avalanche: 'avalanche',
  clouds: 'clouds',
  drrf: 'drrf',
  hail: 'hail-size',
  lightningRisk: 'ltng-risk',
  opensnowLocationsCompare: 'opensnow-locations-compare',
  rala: 'rala',
  radarGlobal: 'radar-global-p',
  radarJapan: 'radar-jma',
  smokeHigh: 'smoke-high',
  smokeHighHighRes: 'smoke-high-hr',
  smokeLow: 'smoke-low',
  smokeLowHighRes: 'smoke-low-hr',
  snowdepth: 'snowdepth',
  snowfall24h: 'snowfall-24h',
  snowfallSeason: 'snowfall-season',
  temps: 'temps',
  weatherStations: 'weather-stations',
  wildfire: 'wildfire',
  wind: 'wind',
  publicLands: 'public-lands',
  parcels: 'parcels',
  fallcolors: 'fall-colors',
};
