<template>
  <div
    id="layerDrawer"
    :class="containerClass"
  >
    <CardCloseButton
      @click="setIsLayerDrawerOpen(false)"
    />
    <Tabs
      class="tw-h-full"
      :selected-tab="selectedLayerDrawerTab"
      @click="setSelectedLayerDrawerTab"
    >
      <Tab
        v-if="canSelectOverlays"
        :contain-overscroll="true"
        :is-active="selectedLayerDrawerTab === 0"
        title="Overlay Maps"
      >
        <OverlayControl />
      </Tab>
      <Tab
        v-if="canSelectBaseMaps"
        :contain-overscroll="true"
        :is-active="selectedLayerDrawerTab === 1"
        title="Base Maps"
      >
        <BaseMapControl />
      </Tab>
      <Tab
        v-if="canSelectLocationTypes"
        :contain-overscroll="true"
        :is-active="selectedLayerDrawerTab === 2"
        title="Settings"
      >
        <LocationControl />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

export default {
  name: 'LayerDrawer',

  props: {
    canSelectBaseMaps: {
      type: Boolean,
      default: false,
    },
    canSelectLocationTypes: {
      type: Boolean,
      default: false,
    },
    canSelectOverlays: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useMapStore, {
      selectedLayerDrawerTab: (state) => state.ui.selectedLayerDrawerTab,
    }),

    containerClass() {
      return [
        'tw-pt-2.5 tw-px-3 md:tw-px-5',
        'card-background-color',
        'tw-border-solid tw-border-t border-color',
        this.$style.container,
      ];
    },
  },

  methods: {
    ...mapActions(useMapStore, [
      'setIsLayerDrawerOpen',
      'setMapUiProperties',
    ]),

    setSelectedLayerDrawerTab(selectedLayerDrawerTab) {
      this.setMapUiProperties({ selectedLayerDrawerTab });
    },
  },
};
</script>

<style module>
.container {
  box-shadow: inset 0 -10px 10px -10px var(--gray-200);
}
</style>
