<script>
import { mapActions, mapState } from 'pinia';
import { mapCardTypes } from '@@/stores/Map';
import { addSource, colorInterpolate } from './Utils';
import AnimatedOverlayMixin from './Mixins/AnimatedOverlayMixin';
import OpacityMixin from './Mixins/OpacityMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import OverlayNames from './OverlayNames';

export default {
  name: 'AvalancheOverlay',

  mixins: [
    AnimatedOverlayMixin,
    OpacityMixin,
    OverlayMixin,
  ],

  props: {
    bounds: {
      type: [Array, Object],
      default: null,
    },
    isLocationAvalancheForecast: {
      type: Boolean,
      default: false,
    },
    shouldFitBounds: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPoint: null,
      frames: [],
      isAllAccess: false,
      isForecast: false,
      source: {
        get_forecasts: false,
        has_legend: true,
        has_tile_index: true,
        short_name: OverlayNames.avalanche,
        tile_count: 7,
        tile_sort: 'asc',
        tile_types: ['points'],
      },
    };
  },

  computed: {
    ...mapState(useMapStore, {
      mapCard: (state) => state.ui.mapCard,
    }),
  },

  watch: {
    /**
     * Override watch from AnimatedOverlayMixin so that the avalanche card can be
     * updated to show the avalanche forecast for the current frame.
     */
    currentFrame(currentFrame, previousFrame) {
      if (this.isActive) {
        this.showFrame(currentFrame);
        this.hideFrame(previousFrame);
        this.updateAvalancheCard();
      }
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setAvalancheCard', 'setMapUiProperties']),

    async add() {
      await this.updateMapSourceAndFrames();

      if (!this.isLocationAvalancheForecast) {
        this.startStaleCheckInterval({
          beforeUpdateFrames: () => this.removeEvents(),
          afterUpdateFrames: () => this.addEvents(),
        });
      }

      if (this.frames.length === 0) {
        this.addExtremePatternImage();
        this.tiles.forEach((tile) => this.addFrame(tile));
        this.addEvents();
      }

      this.show();

      if (this.isMiniMap) {
        this.setShowAnimationControl(false);
      }

      if (this.shouldFitBounds && this.bounds) {
        this.map.fitBounds(this.bounds);
      }
    },

    addEvents() {
      if (this.isMiniMap && !this.isLocationAvalancheForecast) {
        // Disable events in the Mini-Map on the Location Weather page but not on the Location
        // Avalanche Forecast page.
        return;
      }

      this.frames.forEach((frame) => {
        const layers = frame.layers.slice(0, 2);

        layers.forEach((layer) => {
          const { id } = layer;
          this.map.on('click', id, this.handleClick);
        });
      });
    },

    addExtremePatternImage() {
      const drawBgPattern = (fillColor, strokeColor) => {
        const canvas = document.createElement('canvas');
        canvas.width = 50;
        canvas.height = 50;

        const ctx = canvas.getContext('2d');
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = strokeColor;

        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.moveTo(0, canvas.height);
        ctx.lineTo(canvas.width, 0);
        ctx.lineWidth = 1.25;
        ctx.stroke();

        return ctx.getImageData(0, 0, canvas.width, canvas.height);
      };

      if (!this.map.hasImage('avalanche-extreme-pattern')) {
        this.map.addImage(
          'avalanche-extreme-pattern',
          drawBgPattern(
            this.mapSource.legends[0].steps[5].color_light,
            this.mapSource.legends[0].steps[4].color_light,
          ),
          { pixelRatio: 4 },
        );
      }
    },

    addFillExtremeLayer(tile, layerBelow) {
      const { name } = tile.index_json;
      const id = `${name}-extreme`;

      this.map.addLayer({
        id,
        'type': 'fill',
        'source': name,
        'source-layer': tile.source_layer_id,
        'filter': ['==', ['get', 'danger_level'], 5],
        'layout': {
          visibility: 'none',
        },
        'paint': {
          'fill-antialias': true,
          'fill-outline-color': 'rgba(0,0,0,0)',
          'fill-opacity': 0,
          'fill-pattern': 'avalanche-extreme-pattern',
        },
      }, layerBelow);

      return { id, opacities: ['fill-opacity'] };
    },

    addFillLayer(tile, layerBelow) {
      const { name } = tile.index_json;

      this.map.addLayer({
        'id': name,
        'type': 'fill',
        'source': name,
        'source-layer': tile.source_layer_id,
        'filter': ['<', ['get', 'danger_level'], 5],
        'layout': {
          'visibility': 'none',
          'fill-sort-key': ['get', 'danger_level'],
        },
        'paint': {
          'fill-antialias': true,
          'fill-outline-color': 'rgba(0,0,0,0)',
          'fill-opacity': 0,
          'fill-color': [
            'interpolate',
            ['linear'],
            ['get', 'danger_level'],
            ...colorInterpolate(this.mapSource.legends[0].steps),
          ],
        },
      }, layerBelow);

      return { id: name, opacities: ['fill-opacity'] };
    },

    addFrame(tile) {
      /* eslint camelcase: off */
      const { source_timestamp } = tile;

      const source = addSource({
        tile,
        type: 'vector',
        promoteId: 'shortname',
      }, this);

      if (!source) {
        return;
      }

      const layerBelow = this.map.getLayer('hillshade') ? 'hillshade' : 'road-primary';
      const fillLayer = this.addFillLayer(tile, layerBelow);
      const fillExtremeLayer = this.addFillExtremeLayer(tile, layerBelow);
      const lineLayer = this.addLineLayer(tile, layerBelow);
      const labels = this.addLabels(tile);

      const frame = {
        labels,
        layers: [
          fillLayer,
          fillExtremeLayer,
          lineLayer,
        ],
        source,
        source_timestamp,
      };

      this.frames.push(frame);
    },

    addLabels(tile, layerBelow) {
      const { name } = tile.index_json;
      const id = `${name}-id`;
      const textColor = 'hsl(26, 20%, 36%)';
      const textColorAlt = 'hsl(0, 0%, 78%)';
      const textHalo = 'hsla(35, 16%, 100%, 0.5)';
      const textHaloAlt = 'hsla(0, 0%, 13%, 0.75)';

      this.map.addLayer({
        id,
        'type': 'symbol',
        'source': name,
        'source-layer': tile.source_layer_id,
        'minzoom': 5,
        'layout': {
          'symbol-spacing': 350,
          'text-field': ['get', 'name'],
          'text-font': this.mapStyle.textFont,
          'text-size': 14,
          'visibility': 'none',
        },
        'paint': {
          'text-color': [
            'step',
            ['get', 'danger_level'],
            textColor, 0,
            textColor, 4,
            textColorAlt,
          ],
          'text-halo-color': [
            'step',
            ['get', 'danger_level'],
            textHalo, 0,
            textHalo, 4,
            textHaloAlt,
          ],
          'text-halo-width': 1,
          'text-halo-blur': 0.5,
          'text-opacity': 0,
        },
      }, layerBelow);

      return { id, moveToTop: true, opacities: ['text-opacity'] };
    },

    addLineLayer(tile, layerBelow) {
      const { name } = tile.index_json;
      const id = `${name}-line`;

      this.map.addLayer({
        id,
        'type': 'line',
        'source': name,
        'source-layer': tile.source_layer_id,
        'layout': {
          'visibility': 'none',
          'line-sort-key': ['get', 'danger_level'],
        },
        'paint': {
          'line-opacity': 0,
          'line-color': ['match', ['get', 'danger_level'], 5, '#ED1B24', '#293D52'],
          'line-width': ['match', ['get', 'danger_level'], 5, 3, 2],
          'line-blur': 1,
        },
      }, layerBelow);

      return { id, opacities: ['line-opacity'] };
    },

    handleClick(e) {
      const { point } = e;
      const [feature] = e.features;
      const currentLayerIds = this.frames[this.currentFrame].layers.map((layer) => layer.id);

      if (!currentLayerIds.includes(feature.layer.id)) {
        // Ignore click events on layers not in the current frame!
        return;
      }

      // Stop propagation if this event will be handled so that the map card isn't accidentally
      // reopened when the current avalanche layer is clicked a second time.
      e.originalEvent.stopImmediatePropagation();

      window.setTimeout(() => {
        if (e.originalEvent.handledBy) {
          // Ignore the event if an upper layer, i.e. the locations overlay, has already handled it
          // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
          return;
        }

        e.originalEvent.handledBy = this.$options.name;

        if (this.isMiniMap) {
          this.$router.push(`/avalanche/${feature.properties.shortname}`);
        }
        else if (this.mapCard
          && this.mapCard.type === mapCardTypes.avalancheForecast
          && this.mapCard.data.forecast.shortname === feature.properties.shortname) {
          // Hide the map card if the current avalanche layer is clicked a second time.
          this.setMapUiProperties({ mapCard: null });
        }
        else {
          this.currentPoint = point;
          this.setAvalancheCard(feature.properties);
        }
      }, 100);
    },

    removeEvents() {
      if (this.isMiniMap && !this.isLocationAvalancheForecast) {
        return;
      }

      this.frames.forEach((frame) => {
        const layers = frame.layers.slice(0, 2);

        layers.forEach((layer) => {
          const { id } = layer;
          this.map.off('click', id, this.handleClick);
        });
      });
    },

    updateAvalancheCard() {
      const { avalancheForecast } = mapCardTypes;

      if (this.mapCard && this.mapCard.type === avalancheForecast) {
        const layers = this.frames[this.currentFrame].layers.map((layer) => layer.id);
        const features = this.map.queryRenderedFeatures(this.currentPoint, { layers });

        if (features && features[0]) {
          this.setAvalancheCard(features[0].properties);
        }
      }
    },
  },
};
</script>
