import { mapActions, mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';

export default {
  computed: {
    ...mapState(useMapStore, { opacity: (state) => state.ui.opacity }),

    defaultOpacity() {
      const mapStore = useMapStore();
      return mapStore.data.sources?.[this.source.short_name]?.default_opacity ?? 0.75;
    },

    overlayOpacity() {
      return this.opacity || this.defaultOpacity;
    },
  },

  watch: {
    isActive(value) {
      if (value) {
        this.setMapUiProperties({ opacity: this.defaultOpacity });
      }
    },

    opacity() {
      this.handleOpacityChange();
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    handleOpacityChange() {
      const setOpacity = (l) => {
        if (l.opacities) {
          l.opacities.forEach((opacity, idx) => {
            // allow layers to set opacities independently for each opacity property
            // and allow more complex expressions or functions to be used in addition
            // to numeric values between 0 and 1
            const opacityValue = l.opacityValues?.[idx] ?? null;

            if (opacityValue === null) {
              return this.map.setPaintProperty(l.id, opacity, this.opacity);
            }

            if (opacityValue instanceof Function) {
              return this.map.setPaintProperty(l.id, opacity, opacityValue(this.opacity));
            }

            // IF multiple opacity properties are set,
            // keep the relative relationship of opacity values consistent
            // instead of applying a single user specified value to all properties
            return this.map.setPaintProperty(l.id, opacity,
              Math.min(opacityValue * (1 / this.defaultOpacity) * this.opacity, 1));
          });
        }
      };

      if (!this.isActive) {
        return;
      }

      if (this.layers && this.layers.length) {
        this.layers.forEach((layer) => setOpacity(layer));
      }
      else if (this.frames && this.frames.length) {
        const frame = this.frames[this.currentFrame];

        if (frame) {
          const { labels, layer, layers } = frame;

          if (layers) {
            layers.forEach((l) => setOpacity(l));
          }
          else if (layer) {
            setOpacity(layer);
          }

          if (labels) {
            // sometimes labels are arrays, and sometimes it's just one
            // unlike layer vs layers, so we should handle both
            if (Array.isArray(labels)) {
              labels.forEach((l) => setOpacity(l));
            }
            else {
              setOpacity(labels);
            }
          }
        }
      }
    },
  },
};
