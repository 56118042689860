<script>
import { mapActions, mapState } from 'pinia';
import mapboxgl from 'mapbox-gl';
import { createFeatureCollection, fitBoundsToFeatureCollection } from '@@/utils/MapUtils';
import { getLocationPath, isSavedLocation } from '@@/utils/CommonUtils';
import { mapCardTypes } from '@@/stores/Map';
import MarkerMixin from './Mixins/MarkerMixin';
import OverlayMixin from './Mixins/OverlayMixin';
import StaticOverlayMixin from './Mixins/StaticOverlayMixin';

const favoriteLocationsSourceId = 'favorite-locations-source';
const favoriteLocationsLayer = 'favorite-locations';
const favoriteLocationsLabelLayer = 'favorite-locations-labels';
const locationLayer = 'single-locations';
const locationLabelLayer = 'location-labels';

export default {
  name: 'LocationsOverlay',

  mixins: [
    MarkerMixin,
    OverlayMixin,
    StaticOverlayMixin,
  ],

  props: {
    isFavorites: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      favoriteLocationsFeatureCollection: null,
      isAllAccess: false,
      layers: [],
      mapSourceId: null,
      popup: null,
      popupTimeout: null,
      source: {
        get_forecasts: false,
        has_legend: true,
        has_tile_index: true,
        short_name: 'opensnow-locations-unclustered',
        tile_count: 1,
        tile_sort: 'asc',
        tile_types: ['points'],
      },
    };
  },

  computed: {
    ...mapState(useMapStore, {
      mapCard: (state) => state.ui.mapCard,
      showLocations: (state) => state.ui.showLocations,
      visibleFavoriteLists: (state) => state.ui.visibleFavoriteLists,
      visibleLocationTypes: (state) => state.ui.visibleLocationTypes,
    }),

    ...mapState(useMetaStore, {
      allLocationTypes: (state) => state.location_types,
      favoriteListTypes: (state) => state.favorite_list_types,
    }),
    ...mapState(useMetaStore, ['getLocationTypeBySlug', 'skiAreaLocationTypes']),

    ...mapState(useUserFavoritesStore, {
      favoriteIds: (state) => state.user?.favoriteIds,
      lists: (state) => state.user?.lists,
    }),
    ...mapState(useUserFavoritesStore, ['getSummerLists']),

    ...mapState(useUserStore, ['isGuest']),

    canFilterByFavorites() {
      return this.lists?.length && !this.isGuest;
    },

    defaultFilter() {
      let favoriteIdsFilter;

      // In the Favorites Map only favorite locations are shown, all other locations are hidden.
      if (this.isFavorites) {
        return false;
      }

      const typeIds = this.visibleLocationTypes.reduce((acc, slug) => {
        if (slug !== 'other') {
          const locationType = this.getLocationTypeBySlug(slug);

          if (locationType) {
            acc.push(locationType.id);
          }
        }

        return acc;
      }, []);

      if (this.visibleLocationTypes.includes('other')) {
        const skiAreaLocationTypeIds = this.skiAreaLocationTypes.map(({ id }) => id);

        const otherLocationTypeIds = this.allLocationTypes.reduce((acc, { id }) => {
          if (!skiAreaLocationTypeIds.includes(id)) {
            acc.push(id);
          }

          return acc;
        }, []);

        typeIds.push(...otherLocationTypeIds);
      }

      const typeIdsFilter = ['in', ['get', 'type_id'], ['literal', typeIds]];

      if (favoriteIdsFilter) {
        return ['all', favoriteIdsFilter, typeIdsFilter];
      }

      return typeIdsFilter;
    },

    favoritesFilter() {
      let favoriteListsFilter;

      if (this.visibleFavoriteLists === null) {
        const allFavoriteIds = this.favoriteIds?.favorites?.location_ids || [];
        favoriteListsFilter = ['!', ['in', ['get', 'id'], ['literal', allFavoriteIds]]];
      }
      else if (this.visibleFavoriteLists.length) {
        const visibleFavoriteIds = this.visibleFavoriteLists.reduce((acc, id) => {
          const list = this.favoriteIds?.lists?.find(({ list_id }) => list_id === id);

          if (list) {
            acc.push(...list.location_ids);
          }

          return acc;
        }, []);

        favoriteListsFilter = ['in', ['get', 'id'], ['literal', visibleFavoriteIds]];
      }

      return favoriteListsFilter;
    },

    isActive() {
      const isActive = !!(this.showLocations && this.isMapLoaded && this.map);
      return isActive;
    },

    layerBefore() {
      return this.map?.getLayer('settlement-label')
        ? 'settlement-label'
        : 'settlement-minor-label';
    },
  },

  watch: {
    favoriteIds: {
      deep: true,
      handler() {
        this.updateDefaultFilter();
        this.updateFavoritesFilter();
      },
    },

    mapCard(newValue, oldValue) {
      if (!this.isActive) {
        return;
      }

      if (oldValue && newValue) {
        // When an old map card is hidden and a new one is shown then unselect the location of the
        // old map card and select the location of the new map card
        this.unselectLocation(oldValue);
        this.selectLocation(newValue);
      }
      else if (newValue) {
        // When a new map card is shown then select the location of the new map card
        this.selectLocation(newValue);
      }
      else if (oldValue) {
        // When an old map card is hidden then unselect the location of the old map card
        this.unselectLocation(oldValue);
      }
    },

    visibleFavoriteLists() {
      this.updateFavoritesFilter();
    },

    visibleLocationTypes() {
      this.updateDefaultFilter();
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setMapLocation', 'setMapUiProperties']),
    ...mapActions(useUserFavoritesStore, ['fetchFavoriteLocations']),

    async add() {
      await this.checkForUpdatedMapSource();

      if (this.layers.length > 0) {
        this.show();
        return;
      }

      this.createDefaultLayers();
      await this.createFavoriteLayers();
      await this.createPopup();
      this.addEvents();
    },

    addEvents() {
      if (this.isFavorites) {
        this.map.on('render', this.handleRender);
      }
      else {
        this.map.on('popup.show', this.handlePopupShow);
      }

      this.layers.forEach((layer) => {
        const { id } = layer;

        this.map.on('click', id, this.handleClick);

        if (!this.$device.isMobileOrTablet) {
          this.map.on('mouseenter', id, this.showPopup);
          this.map.on('mouseleave', id, this.hidePopup);
        }
      });
    },

    addMarkerIfPossible(mapCard) {
      const lngLat = mapCard?.data?.coordinates || mapCard?.data?.location?.coordinates?.point;

      if (this.marker || !lngLat) {
        // Don't add the marker again! And just return if no lng/lat is present.
        return;
      }

      this.addMarker(lngLat, this.map);
    },

    createDefaultLayers() {
      const sourceLayer = this.mapSource.tiles.points[0].source_layer_id;

      this.mapSourceId = this.shortName;

      this.map.addSource(this.mapSourceId, {
        ...this.mapSource.tiles.points[0].index_json,
        type: 'vector',
        promoteId: 'shortname',
      });

      const circleLayerParams = this.getCircleLayerParams(
        locationLayer,
        this.shortName,
        sourceLayer,
        this.defaultFilter,
      );

      const labelLayerParams = this.getLabelLayerParams(
        locationLabelLayer,
        this.shortName,
        sourceLayer,
        this.defaultFilter,
      );

      this.map.addLayer(circleLayerParams, this.layerBefore);
      this.layers.push({ id: locationLayer });

      this.map.addLayer(labelLayerParams, this.layerBefore);
      this.layers.push({ id: locationLabelLayer });
    },

    async createFavoriteLayers() {
      /* eslint camelcase: off, no-await-in-loop: off, no-continue: off */
      if (!this.canFilterByFavorites) {
        return;
      }

      const favoriteLocations = [];

      for (let i = 0; i < this.lists.length; i += 1) {
        const { id } = this.lists[i];

        const { locations } = await this.fetchFavoriteLocations({
          list_id: id,
          saveToStore: false,
        });
        favoriteLocations.push(...locations);
      }

      this.favoriteLocationsFeatureCollection = createFeatureCollection(favoriteLocations);

      this.map.addSource(favoriteLocationsSourceId, {
        data: this.favoriteLocationsFeatureCollection,
        type: 'geojson',
        promoteId: 'shortname',
      });

      const favoriteLocationsCircleLayerParams = this.getCircleLayerParams(
        favoriteLocationsLayer,
        favoriteLocationsSourceId,
        '',
        this.favoritesFilter,
      );

      const favoriteLocationsLabelLayerParams = this.getLabelLayerParams(
        favoriteLocationsLabelLayer,
        favoriteLocationsSourceId,
        '',
        this.favoritesFilter,
      );

      this.map.addLayer(favoriteLocationsCircleLayerParams, this.layerBefore);
      this.layers.push({ id: favoriteLocationsLayer });

      this.map.addLayer(favoriteLocationsLabelLayerParams, this.layerBefore);
      this.layers.push({ id: favoriteLocationsLabelLayer });
    },

    async createPopup() {
      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: 'none',
        offset: 10,
        className: 'location-hover-popup',
      });
    },

    getCircleLayerParams(id, source, sourceLayer = '', filter) {
      return {
        id,
        'type': 'circle',
        source,
        'source-layer': sourceLayer,
        filter,
        'paint': {
          'circle-opacity': 1,
          'circle-stroke-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#666666',
            '#e57700',
          ],
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 3, 6, 3, 8, 8, 11, 6],
          'circle-color': [
            'case',
            ['boolean', ['feature-state', 'active'], false],
            '#e57700',
            '#fffbe7',
          ],
          'circle-stroke-width': ['interpolate', ['linear'], ['zoom'], 0, 0.5, 6, 1, 8, 2, 11, 2],
        },
      };
    },

    getLabelLayerParams(id, source, sourceLayer = '', filter) {
      return {
        id,
        'type': 'symbol',
        source,
        'minzoom': 7,
        filter,
        'source-layer': sourceLayer,
        'layout': {
          'text-allow-overlap': false,
          'text-anchor': 'left',
          'text-field': ['get', 'name'],
          'text-font': this.mapStyle.textFont,
          'text-ignore-placement': false,
          'text-justify': 'left',
          'text-letter-spacing': this.mapStyle.textLetterSpacing,
          'text-line-height': this.mapStyle.textLineHeight,
          'text-offset': [1, 0],
          'text-padding': 15,
          'text-size': this.mapStyle.textSize,
        },
        'paint': {
          'text-color': this.mapStyle.textColor,
          'text-halo-color': this.mapStyle.textHaloColor,
          'text-halo-blur': this.mapStyle.textHaloBlur,
          'text-halo-width': this.mapStyle.textHaloWidth,
        },
      };
    },

    handleClick(e) {
      // The features property of the event disappears by the time the timeout handler is called.
      // It's not clear why this happens. So as a workaround, the features are saved in the closure
      // here for reference in the timeout.
      const { features } = e;

      window.setTimeout(async () => {
        // Ignore the event if an upper layer, i.e. the Weather Stations Overlay, has already
        // handled it!
        // SEE: https://github.com/mapbox/mapbox-gl-js/issues/5783
        if (e.originalEvent.handledBy) {
          return;
        }

        // Prevent event propagation so that the <MapCard> remains visible if already showing a
        // different location. And prevent default so that the the avalanche overlay knows when the
        // event has already been handled.
        e.preventDefault();
        e.originalEvent.stopImmediatePropagation();
        e.originalEvent.handledBy = this.$options.name;

        const location = features[0].properties;
        const { shortname } = location;

        try {
          if (this.isMiniMap) {
            const path = getLocationPath(location, this.skiAreaLocationTypes);
            this.$router.push(path);
          }
          else if (this.mapCard
            && this.mapCard.type === mapCardTypes.locationForecast
            && this.mapCard.data.shortname === shortname) {
            // Hide the map card if the current location is clicked a second time.
            this.setMapUiProperties({ mapCard: null });
          }
          else {
            await this.setMapLocation({ shortname, updateCoords: false });
          }
        }
        catch (exp) {
          this.$toast.open({
            message: 'Sorry, we were unable to get the forecast for that location!',
            type: 'error',
          });
        }
      }, 50);
    },

    handlePopupShow(data) {
      if (data.overlay === this) {
        return;
      }

      this.hidePopup();
    },

    handleRender() {
      if (this.map.isSourceLoaded(favoriteLocationsSourceId)) {
        this.map.off('render', this.handleRender);
        fitBoundsToFeatureCollection(this.favoriteLocationsFeatureCollection, this.map);
      }
    },

    hidePopup(e) {
      this.popupTimeout = setTimeout(() => {
        this.map.fire('popup.hide', {
          ...e,
          overlay: this,
        });
        this.popup.remove();
      }, 250);
    },

    isAvalancheForecast(mapCard) {
      return mapCard?.data?.type === mapCardTypes.avalancheForecast;
    },

    isOpenSnowLocation(mapCard) {
      return mapCard?.data?.shortname
        && this.map
          .querySourceFeatures(this.mapSourceId, { sourceLayer: this.mapSourceId })
          .find(({ id }) => id === mapCard.data.shortname);
    },

    isSavedFavoriteLocation(mapCard) {
      return isSavedLocation(mapCard?.data?.location)
        && this.map
          .querySourceFeatures(favoriteLocationsSourceId, {
            sourceLayer: favoriteLocationsSourceId,
          })
          .find(({ id }) => id === mapCard.data.shortname);
    },

    remove() {
      this.removeMarker();
      this.removeEvents();

      this.layers.forEach((layer) => {
        if (this.map.getLayer(layer.id)) {
          this.map.removeLayer(layer.id);
        }
      });

      if (this.map.getSource(this.mapSourceId)) {
        this.map.removeSource(this.mapSourceId);
      }

      if (this.map.getSource(favoriteLocationsSourceId)) {
        this.map.removeSource(favoriteLocationsSourceId);
      }

      this.layers.splice(0);
    },

    removeEvents() {
      if (!this.isFavorites) {
        this.map.off('popup.show', this.handlePopupShow);
      }

      this.layers.forEach((layer) => {
        const { id } = layer;

        this.map.off('click', id, this.handleClick);

        if (!this.$device.isMobileOrTablet) {
          this.map.off('mouseenter', id, this.showPopup);
          this.map.off('mouseleave', id, this.hidePopup);
        }
      });
    },

    selectLocation(mapCard) {
      this.removeMarker();

      if (this.isAvalancheForecast(mapCard)) {
        return;
      }

      if (this.isOpenSnowLocation(mapCard) || this.isSavedFavoriteLocation(mapCard)) {
        this.setFeatureState(mapCard, true);
      }
      else {
        this.addMarkerIfPossible(mapCard);
      }
    },

    setFeatureState(mapCard, active) {
      let feature;

      if (this.isSavedFavoriteLocation(mapCard)) {
        feature = {
          id: mapCard.data.shortname,
          source: favoriteLocationsSourceId,
        };
      }
      else {
        feature = {
          id: mapCard.data.shortname,
          source: this.mapSourceId,
          sourceLayer: this.mapSourceId,
        };
      }

      this.map.setFeatureState(feature, { active });
    },

    showPopup(e) {
      window.clearTimeout(this.popupTimeout);

      this.map.fire('popup.show', {
        ...e,
        overlay: this,
      });

      this.popup
        .setLngLat(e.features[0].geometry.coordinates)
        .setHTML(e.features[0].properties.name)
        .addTo(this.map);
    },

    unselectLocation(mapCard) {
      if (this.isAvalancheForecast(mapCard)) {
        return;
      }

      if (this.isOpenSnowLocation(mapCard) || this.isSavedFavoriteLocation(mapCard)) {
        this.setFeatureState(mapCard, false);
      }
      else {
        this.removeMarker();
      }
    },

    updateFavoritesFilter() {
      if (this.isActive) {
        if (this.map.getLayer(favoriteLocationsLayer)
          && this.map.getLayer(favoriteLocationsLabelLayer)) {
          this.map.setFilter(favoriteLocationsLayer, this.favoritesFilter);
          this.map.setFilter(favoriteLocationsLabelLayer, this.favoritesFilter);
        }
      }
    },

    updateDefaultFilter() {
      if (this.isActive) {
        this.map.setFilter(locationLayer, this.defaultFilter);
        this.map.setFilter(locationLabelLayer, this.defaultFilter);
      }
    },
  },
};
</script>
