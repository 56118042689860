<template>
  <LayerSelectionControl
    :current-layer="currentBaseMap"
    :layers="baseMaps"
    :show-badges="true"
    :show-lock="true"
    @click="setCurrentBaseMap"
  />
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';

export default {
  name: 'BaseMapControl',

  computed: {
    ...mapState(useMapStore, {
      baseMaps: (state) => state.data.baseMaps,
    }),

    ...mapState(useMapStore, ['currentBaseMap']),
  },

  methods: {
    ...mapActions(useMapStore, [
      'setCurrentBaseMap',
    ]),
  },
};
</script>
