<template>
  <div :class="containerClass">
    <transition
      enter-active-class="fade-enter-transition"
      enter-from-class="fade-out"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
    >
      <div
        v-if="showMaskMessage"
        :class="bannerClassNames"
      >
        {{ maskMessage }}
        <a
          href="javascript:void(0)"
          :class="buttonClassNames"
          @click="$emit('click')"
        >
          Zoom to Layer
        </a>
        <a
          href="javascript:void(0)"
          :class="secondaryButtonClassNames"
          @click="$emit('clickSecondary')"
        >
          Select New Layer
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint no-console: off */
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { isScreenSm } from '@@/utils/CommonUtils';
import { useMapStore } from '@@/stores/Map';

export default {
  name: 'CoverageBanner',

  emits: ['click', 'clickSecondary'],

  computed: {
    ...mapState(useMapStore, {
      currentOverlay: (state) => state.currentOverlay,
      showMaskMessage: (state) => state.ui.showMaskMessage,
      maskMessage: (state) => state.ui.maskMessage,
    }),

    containerClass() {
      return 'flex tw-absolute tw-bottom-24 tw-z-10 tw-inset-x-5 md:tw-inset-x-40';
    },

    buttonProps() {
      /* eslint no-script-url: off */
      return {
        type: ButtonTypes.secondary,
        displayBlock: this.buttonDisplayBlock,
      };
    },

    bannerClassNames() {
      return [
        'tw-relative',
        'tw-p-4 tw-rounded-lg',
        'tw-text-base tw-font-medium tw-text-center',
        this.$style.banner,
      ];
    },

    buttonClassNames() {
      return [
        'tw-block',
        'md:tw-inline-block',
        'md:tw-self-auto',
        'tw-mt-2',
        'md:tw-mt-0',
        'md:tw-ml-2',
        'tw-px-3',
        'tw-py-1.5',
        'tw-rounded-lg',
        'tw-text-base',
        'hover:tw-no-underline',
        'tw-transition-all',
        'tw-font-normal',
        'tw-text-center',
        this.$style.bannerButton,
      ];
    },

    secondaryButtonClassNames() {
      return [
        'tw-block',
        'md:tw-inline-block',
        'tw-mt-2',
        'md:tw-mt-0',
        'md:tw-ml-2',
        'tw-underline',
        this.$style.secondaryButton,
      ];
    },

    buttonDisplayBlock() {
      return isScreenSm();
    },
  },

  watch: {
    currentOverlay(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.setShowMaskMessage(false);
      }
    },
  },

  methods: {
    ...mapActions(useMapStore, ['setShowMaskMessage']),

    handleClick(e) {
      this.$emit('click', { originalEvent: e });
    },
    handleClickSecondary(e) {
      this.$emit('clickSecondary', { originalEvent: e });
    },
  },
};
</script>

<style module>
.banner {
  background-color: var(--light-orange);
  border: 0.125rem solid var(--saturated-orange);
  color: black;
}

.secondaryButton {
  color: var(--text-dark);
}

@media (hover: hover) and (pointer: fine) {
  .secondaryButton:hover {
    color: var(--text-darkest);
  }
}

.bannerButton {
  background-color: rgb(var(--button-secondary-background-rgb));
  border: var(--button-secondary-border);
  color: var(--text-darkest);
}

.bannerButton:hover {
  background-color: var(--button-secondary-background-hover);
}

.bannerButton:focus {
  background-color: var(--button-secondary-background-hover);
  box-shadow: 0 0 0 0.2rem rgb(var(--button-secondary-background-rgb) / 25%);
}
</style>
