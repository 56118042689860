<template>
  <div
    v-if="isVisible"
    id="animationControl"
    :class="containerClass"
    @click.stop
  >
    <div :class="contentClass">
      <button
        :class="buttonClass"
        :disabled="isDisabled"
        @click.stop="togglePlayback"
      >
        <font-awesome-icon
          class="tw-w-4 tw-h-4"
          :icon="actionIcon"
        />
      </button>
      <RangeInput
        :disabled="isDisabled"
        :full-width="true"
        :max="numberOfFrames - 1"
        :min="0"
        :step="1"
        :model-value="currentFrame"
        @update:model-value="handleRangeInputUpdate"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';

export default {
  name: 'AnimationControl',

  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    frameDuration: {
      type: Number,
      default: 500, // Milliseconds
    },
  },

  data() {
    return {
      hasAutoplayed: false,
      isPlaying: false,
      timeoutId: null,
      wasPlaying: false,
    };
  },

  computed: {
    ...mapState(useMapStore, ['currentOverlay']),

    ...mapState(useMapStore, {
      currentFrame: (state) => state.ui.currentFrame,
      isMapIdle: (state) => state.ui.isMapIdle,
      numberOfFrames: (state) => state.ui.numberOfFrames,
      showAnimationControl: (state) => state.ui.showAnimationControl,
    }),

    actionIcon() {
      return ['fas', this.isPlaying ? 'pause' : 'play'];
    },

    buttonClass() {
      return [
        'tw-h-full tw-mr-2.5',
        'tw-outline-none',
        this.isDisabled ? 'tw-opacity-50 tw-pointer-events-none' : null,
      ];
    },

    containerClass() {
      return [
        'tw-w-full tw-h-4 md:tw-h-8',
        this.isDisabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer',
      ];
    },

    contentClass() {
      return 'tw-flex tw-h-full text-dark-color';
    },

    isDisabled() {
      return this.isMapIdle === false;
    },

    isVisible() {
      return this.currentOverlay && this.showAnimationControl;
    },
  },

  watch: {
    isMapIdle() {
      if (this.isMapIdle === false && this.isPlaying) {
        this.wasPlaying = true;
        this.pause();
      }
      else if (this.isMapIdle === true && this.wasPlaying) {
        this.wasPlaying = false;
        this.play();
      }
      else if (this.isMapIdle === true && this.autoplay && this.hasAutoplayed === false) {
        this.hasAutoplayed = true;
        this.play();
      }
    },

    isVisible(value) {
      if (value) {
        window.addEventListener('keydown', this.handleKeydown);
      }
      else {
        window.removeEventListener('keydown', this.handleKeydown);
      }
    },

    showAnimationControl(value) {
      if (value === false) {
        this.pause();
      }
    },
  },

  unmounted() {
    window.clearTimeout(this.timeoutId);
    window.removeEventListener('keydown', this.handleKeydown);
  },

  methods: {
    ...mapActions(useMapStore, ['setMapUiProperties']),

    handleKeydown(e) {
      // Ignore the keydown event if it happened in an input (i.e. in the site header search input)
      if (e.target.closest('input')) {
        return;
      }

      if (e.keyCode === 37) {
        e.preventDefault();

        const prevFrame = this.currentFrame === 0
          ? this.numberOfFrames - 1
          : this.currentFrame - 1;

        this.pause();
        this.setMapUiProperties({ currentFrame: prevFrame });
      }
      else if (e.keyCode === 39) {
        e.preventDefault();

        const nextFrame = this.currentFrame === this.numberOfFrames - 1
          ? 0
          : this.currentFrame + 1;

        this.pause();
        this.setMapUiProperties({ currentFrame: nextFrame });
      }
      else if (e.keyCode === 32) {
        e.preventDefault();
        this.togglePlayback();
      }
    },

    handleRangeInputUpdate(currentFrame) {
      this.pause();
      this.setMapUiProperties({ currentFrame });
    },

    play(currentFrame) {
      this.isPlaying = true;

      if (currentFrame !== undefined) {
        this.setMapUiProperties({ currentFrame });
        this.timeoutId = window.setTimeout(() => this.play(), this.frameDuration);
        return;
      }

      const nextFrame = this.currentFrame + 1;

      if (nextFrame === this.numberOfFrames) {
        // By convention, meterologists expect there to be a slight pause in radar animation before
        // it starts over at the first frame. So use a delay of 1.5s when wrapping back to the
        // first frame.
        this.timeoutId = window.setTimeout(() => this.play(0), 1500);
      }
      else {
        this.setMapUiProperties({ currentFrame: nextFrame });
        this.timeoutId = window.setTimeout(() => this.play(), this.frameDuration);
      }
    },

    pause() {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
      this.isPlaying = false;
    },

    togglePlayback() {
      if (this.isPlaying) {
        this.pause();
      }
      else {
        this.play();
      }
    },
  },
};
</script>
