<template>
  <span
    v-if="isVisible"
    :class="textClass"
  >
    {{ formattedTimestamp }}
  </span>
</template>

<script>
import { mapState } from 'pinia';
import { useMapStore } from '@@/stores/Map';

export default {
  name: 'OverlayTimestamp',

  computed: {
    ...mapState(useMapStore, ['currentOverlay']),

    ...mapState(useMapStore, {
      currentTimestamp: (state) => state.ui.currentTimestamp,
    }),

    formattedTimestamp() {
      const dayJsDate = this.$dayjs(this.currentTimestamp);
      const datePart = dayJsDate.format('ddd MMM D');
      const timePart = dayJsDate.format('h:mm a z');

      return `${datePart} at ${timePart}`;
    },

    isVisible() {
      return this.currentOverlay && this.currentTimestamp;
    },

    textClass() {
      return 'tw-block tw-font-medium tw-text-xs text-dark-color';
    },
  },
};
</script>
