<script setup>
const props = defineProps({
  hasBodyShadow: {
    type: Boolean,
    default: false,
  },
  location: {
    type: Object,
    default: null,
  },
  showActions: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['close-button-click']);

const forecastCurrent = computed(() => props.location?.forecast_current);
const hasLocation = computed(() => !!props.location);
</script>

<template>
  <Card
    :has-body-padding-small="true"
    :has-body-shadow="hasBodyShadow"
    :show-close-button="showCloseButton"
    @close-button-click="$emit('close-button-click')"
  >
    <template #body>
      <div
        v-if="!hasLocation"
        :class="['tw-flex tw-items-center', $style.loading]"
      >
        <LoadingIndicator />
      </div>
      <div v-else>
        <SnowLocationHeader
          :in-map-card="true"
          :location="location"
        />
        <h2 class="tw-flex tw-items-center tw-my-3 section-title heading-color">
          <span class="tw-mr-2">
            Current Conditions
          </span>
          <EstimatedBadge
            :extra-small="true"
          />
        </h2>
        <CurrentConditions
          class="tw-mb-5"
          current-details-class-names="lg:tw-w-1/2"
          :forecast-current="forecastCurrent"
        />
        <MapCardActions
          v-if="showActions"
          class="tw-mt-2"
          :location="location"
          @close-button-click="$emit('close-button-click')"
        />
      </div>
    </template>
  </Card>
</template>

<style module>
.loading {
  min-height: 14rem;
}
</style>
