<template>
  <div
    id="controlContainer"
    :class="controlContainerClass"
  >
    <ControlBar
      :class="controlBarClass"
    />
    <OverlayLegend
      :v-if="hasLegend"
      :class="overlayLegendClass"
    />
    <LayerDrawer
      :can-select-base-maps="canSelectBaseMaps"
      :can-select-location-types="canSelectLocationTypes"
      :can-select-overlays="canSelectOverlays"
      :class="[controlClass, layerDrawerClass]"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
  name: 'ControlContainer',

  props: {
    canSelectBaseMaps: {
      type: Boolean,
      default: false,
    },
    canSelectLocationTypes: {
      type: Boolean,
      default: false,
    },
    canSelectOverlays: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useMapStore, ['currentOverlay']),

    ...mapState(useMapStore, {
      isLayerDrawerOpen: (state) => state.ui.isLayerDrawerOpen,
    }),

    controlBarClass() {
      return `${this.controlClass} tw-top-0`;
    },

    controlClass() {
      return 'tw-absolute tw-w-full tw-z-20';
    },

    controlContainerClass() {
      return [
        'tw-absolute',
        'tw-w-full',
        this.hasLegend ? this.$style.controlContainer : 'tw-h-14',
        'tw-overflow-visible',
      ];
    },

    hasLegend() {
      return !!this.currentOverlay?.legends?.length;
    },

    layerDrawerClass() {
      return [
        'tw-z-10',
        this.hasLegend ? this.$style.layerDrawer : 'tw-mb-14',
        'tw-bottom-0',
        this.isLayerDrawerOpen ? `${this.$style.showLayerDrawer}` : 'tw-h-0 tw-opacity-0',
        this.$style.slideTransition,
      ];
    },

    overlayLegendClass() {
      return `${this.controlClass} tw-bottom-0`;
    },
  },
};
</script>

<style module>
.controlContainer {
  height: 5rem;
}

@media (min-width: 992px) {
  .controlContainer {
    height: 4.5rem;
  }
}

.layerDrawer {
  margin-bottom: 5rem;
}

@media (min-width: 992px) {
  .layerDrawer {
    margin-bottom: 4.5rem;
  }
}

.slideTransition {
  overflow: hidden;
  transition-duration: 250ms;
  transition-property: height, opacity;
  transition-timing-function: ease-in-out;
}

.showLayerDrawer {
  height: 23.75rem;
  opacity: 0.95;
}

@media (min-width: 992px) {
  .showLayerDrawer {
    height: 27.5rem;
  }
}

@media (max-height: 700px) {
  .showLayerDrawer {
    height: 50vh;
  }
}
</style>
